@import "../../assets/styles/variables.scss";

.concepts-select-wrapper {
  display: flex;
  flex-direction: column;
  border-bottom: $cresta-row-divider;

  .concepts-select-title {
    color: $cresta-placeholder;
    padding: 5px 0px;
  }

  .library-concept {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
    padding: 5px;
    border-top: $cresta-row-divider;
  
    & > *:not(:last-child):not(.spacer) {
      margin-right: 5px;
    }
  
    &:hover {
      background-color: $cresta-alto;
    }

    .spacer {
      margin: auto;
    }
  }
}