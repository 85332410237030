
.labeling-task-status {
  display: flex;
  align-items: center;
  &:before {
    flex-shrink: 0;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    margin-right: 4px;
  }
  .ant-spin {
    transform: scale(0.8);
    margin-left: -2px;
    position: relative;
    left: -2px;
  }
  &.completed {
    &:before {
      background: #89b4f3;
    }
  }  
  &.ready {
    &:before {
      background: #20C997;
    }
  }
  &.created {
    &:before {
      background: #f1efd0;
    }
  }  
  &.in-progress {
    &:before {
      background: #FCC419;
    }
  }
  &.not-started {
    &:before {
      background: #C4C4C4;
    }
  }
  &.error {
    &:before {
      background: #d32121;
    }
  }  
  &.preparing {
    &:before {
      display: none;
    }
  }
}