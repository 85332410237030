@import 'assets/styles/variables.scss';

.syntheticConversationModal{
  padding: 80px;
  .syntheticConversationModalTitle{
    color: #212121;
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 24px;
  }
  .syntheticConversationModalButton {
    padding: 16px;
    border: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    cursor: pointer;
    &:hover {
      background: #EDF2FF;
      border: 1px solid #304FFE;
    }
    .syntheticConversationModalButtonText {
      font-size: 14px;
      color: #304FFE;
      margin-left: 16px;
    }
  }
  .syntheticConversationModalList {
    max-height: 672px;
    overflow: auto;
  }
}