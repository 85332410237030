@import "../../../assets/styles/variables.scss";

.intent-concept-editor-placeholder {
  color: $cresta-placeholder;
}

.intent-concept-editor-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;

  .spacer {
    margin: auto;
  }
}

.intent-editor-concept-tag {
  margin-bottom: 20px;
}

.intent-concept-editor-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 40px;

  & > *:not(:last-child) {
    margin-right: 20px;
  }

  .spacer {
    margin: auto;
  }
}

.deprecate-modal-warning-title {
  color: $cresta-red;
}
