@import "../../assets/styles/variables.scss";

.tree-node-icon {
  min-width: 26px;
  height: 26px;
  border-radius: $cresta-border-radius;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  line-height: 26px;
  &:after {
    font-size: 13px;
    color: white;
  }
  &.fullname {
    padding: 0 10px;
    text-transform: uppercase;
  }  
  &.stage {
    background: $cresta-comet;
    &:after {
      content: "S";
    }
  }
  &.agent {
    background: #899AF6;
    &:after {
      content: "A";
    }    
    &.fullname:after {
      content: "Agent";
    }    
  }
  &.visitor {
    background: #EFB183;
    &:after {
      content: "V";
    }
    &.fullname:after {
      content: "Visitor";
    }
  }
  &.chat-driver {
    background: darken($cresta-danube, 10%);
    &:after {
      padding: 0 10px;
      content: "DRIVER";
    }
  }
  &.deprecated {
    background: $cresta-pearl;
  }
}