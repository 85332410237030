.ant-tabs {

}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.ant-tabs-ink-bar {
  background: transparent;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  color: $cresta-pearl;
  font-weight: 500;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $cresta-text;
  font-weight: 500;
}