text {
  font-weight: 300;
  font-size: 12px;
}

g.node:hover {
  cursor: pointer;
}

.node rect {
  stroke: #333;
}

.edgePath path {
  stroke: #333;
  fill: none;
  stroke-width: 1.5px;
}

.edgePath path.selected {
  stroke-width: 6px;
}

.edgePath path.selected.inbound {
  stroke: #ff4d4f;
}

.edgePath path.selected.outbound {
  stroke: #1890ff;
}

.edgeLabel div {
  font-size: 14px;
  white-space: pre-wrap !important;
}

.edgeLabel div:hover {
  cursor: pointer;
}

.edgeLabel div.selected {
  border: 1px solid #b2b2b2;
  border-radius: 2px;
  padding: 2px 16px;
  /*width: 360px;*/
}

.node text {
  pointer-events: none;
}

.node .label {
  font-weight: 300;
  font-size: 16px;
  color: #fff;
}

.node .label text.name {
  line-height: 30px;
}

div#tooltip {
  position: absolute;
  background: #fff;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  padding: 4px;
  z-index: 10;
  max-width: 400px;
  white-space: pre-wrap;
}
