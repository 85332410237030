@import "../../assets/styles/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;

  .calibrationSummaryHeader {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    .subTitle {
      display: flex;
      flex-direction: row;
      align-items: center;

      .label {
        margin-right: 10px;
      }

      .value {
        margin-right: 20px;
      }
    }
  }

  .filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .spacer {
      margin: auto;
    }

    .labelValueSelect {
      width: 240px;
    }

    .searchTextInput {
      width: 240px;
      margin-left: 20px;
    }
  }

  .summaryTable {
    .labelButton {
      border: 0px;
      border-radius: $cresta-border-radius-sm;
      width: 84px;
      height: 25px;
    }

    .intentLabel {
      border: 0px;
      border-radius: $cresta-border-radius-sm;
    }

    .positive {
      background: rgba(81, 207, 102, 0.2);
      color: #51CF66;
    }

    .negative {
      background: rgba(255, 107, 107, 0.2);
      color: #FF6B6B;
    }

    .skipped {
      background: #EDF2FF;
      color: #304FFE;
    }

    .unsure {
      background: rgba(151, 117, 250, 0.2);
      color: #9775FA;
    }
  }
}