.analysis-workshop-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    flex: 1;

    .content {
        border-radius: 12px;
        overflow: hidden;
        gap: 2px;
        min-height: 0px;
        flex: 1;
        display: flex;

        .primary-content {
            gap: 2px;
            flex-direction: column;
            flex: 3;
            display: flex;
            .chatbox-header {
                background: #fff;
                padding: 16px;
                min-height: 90px;
                display: flex;
                justify-content: space-between;
                .right-col {
                    text-align: right;
                }
            }
            .scrolling-content {
                background: #fff;
                padding: 0 16px;
                flex: 1;
                .scrolling-inner{
                    margin: 16px 0;
                }
            }
            .scrolling-content:before {
                content: '';
                position: absolute;
                background-image: linear-gradient(to bottom, white, transparent 16px, transparent calc(100% - 16px), white);
                pointer-events: none;
                height: 100%;
                width:100%;
              }
        }
        .secondary-content {
            background: #fff;
            padding: 32px;
            flex:1;
        }
    }
    .page-footer {
        border-radius: 12px;
        background: #fff;
        padding: 12px 24px;
        display: flex;
        align-items: center;
        .stepper-container {
            flex: 1;
        }
        height: 74px;
    }
}
