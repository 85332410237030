@import "../../../assets/styles/variables.scss";

.policy-decision {
  width: 250px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column; 
  overflow-y: auto;
  padding: 25px;
  .section-title {
    font-size: 16px;
    font-weight: 500;
  } 
  .predicted-sdx-none {
    color: $cresta-balihali;
  }
  .buttons {
    margin-top: 15px;
    & > div {
      display: flex;
      margin: 10px auto 20px;
    }
    button {
      width: 100%;
    }
    button + button {
      margin-left: 20px;
    }
  }  
  .policy-items {
    margin-top: 10px;
  }
  .policy-items + * {
    margin-top: 20px;
  }  
  .expected-intent-select {
    .ant-select-selection-item {
      line-height: initial;
    }
  }
  .proceed-button {
    width: 100%;
    button {
      width: 100%;
    }
  }  
}

.policy-decision-list-item {
  color: $cresta-text;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  opacity: 1;
  &.hoverable {
    cursor: pointer;
  }
  .policy-decision-list-item-checkbox {
    border-radius: $cresta-border-radius-sm;
    border: 1px solid $cresta-alto;
    margin-right: 10px;
    height: 20px;
    width: 20px;
    flex-shrink: 0;
  }
  .policy-decision-list-item-text {
    padding: 8px;
    border-radius: $cresta-border-radius;
    border: 1px solid $cresta-alto;
    display: block;
    width: 100%;
  }
  p {
    white-space: initial;
  }
  &:hover.hoverable {
    .policy-decision-list-item-checkbox, .policy-decision-list-item-text {
      background: lighten($cresta-lightblue, 2%);
    }
  }
  &.positive:not(.selected-all) {
    .policy-decision-list-item-checkbox {
      background: #51CF66; 
      border-color: #51CF66; 
    }
    .policy-decision-list-item-text {
      background: rgba(#51CF66, 0.1);
      border-color: rgba(#51CF66, 0.5);
    }
  }
  &.negative:not(.selected-all) {
    .policy-decision-list-item-checkbox {
      background: #FF6B6B;
      border-color: #FF6B6B;
    }
    .policy-decision-list-item-text {
      background: rgba(#FF6B6B, 0.1);
      border-color: rgba(#FF6B6B, 0.5);
    }
  }      
}
.policy-decision-list-item + .policy-decision-list-item {
  margin-top: 10px;
}

@media screen and (min-width: 1400px) {
  .policy-decision {
    width: 330px;
  }
}