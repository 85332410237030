@import "../../assets/styles/variables.scss";

.wrapper {
  background: $cresta-background;
  width: 100%;
  padding: $ant-modal-padding;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .title {
      font-weight: 600;
      font-size: 24px;
      color: $cresta-text;
    }
  }

  .projectSelect {
    width: 400px;
    margin-bottom: 20px;
  }
}

.radioGroup {
  margin-left: 12px !important;
}

.dependencyUrls {
  display: flex;
  flex-direction: column;
}

.modelUrl {
  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &.highlighted {
    background-color: #ffbbbb;
    color: white;
  }
}

.inlineLink {
  display: inline
}