@import "../../../assets/styles/variables.scss";

.loader-container {
  background: white;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty-chats-container {
  background: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chat-list-container {
  flex: 1;
  border-radius: $cresta-border-radius-lg;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  /* when not active, give it a left border anyway
  * so the active stylings don't shift all contents to the right */

  a {
    text-decoration: none !important;
  }

  .activeChatCard {
    text-decoration: none !important;
  }
}

.chat-list-nav-link {
  background: white;
  //border-radius: $cresta-border-radius-sm;
  &.activeChatCard,
  &:hover {
    background: $cresta-pale-blue;
  }
  .filler {
    background: white;
    flex: 1;
  }
}

.chat-list-load-more-container {
  display: flex;
  justify-content: center;
  background: white;
  .load-more-button {
    margin: 30px 0;
    background: $cresta-blue;
    border-color: $cresta-blue;
  }
}
