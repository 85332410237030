@import 'assets/styles/variables.scss';
/* evaluation results dashboard */
.evaluationResultsTable {
  overflow: hidden;
  border-radius: $cresta-border-radius-lg;
  .tableIntent {
    background-color: $cresta-pale-blue;
    color: $cresta-blue;
    display: inline-block;
    padding: 2px 4px;
    // margin-left: 24px;
    border-radius: $cresta-border-radius-sm;
  }
  .tableFullPrediction {
    background-color: $cresta-white;
    color: $cresta-text;
    display: inline-block;
    padding: 2px 4px;
    border-radius: $cresta-border-radius-sm;
  }
  .tablePrediction {
    background: rgba(255, 107, 107, 0.2);
    color: #ff6b6b;
    display: inline-block;
    padding: 2px 4px;
    border-radius: $cresta-border-radius-sm;
  }
}
