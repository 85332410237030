@import "../../assets/styles/variables.scss";

$border-left-offset: 6px;
$group-left-offset: 60px;
$tree-lines-color: $cresta-alto;

.tree-nodes-group {
  position: relative;
  z-index: 0;
  &:before {
    left: $group-left-offset + $border-left-offset;
  }
  padding-left: $group-left-offset;  
  &:not(:last-of-type):not(.level-0) {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      border-left: 2px solid $tree-lines-color;
    }
  }
  &.level-0 {
    padding-left: 0;
  }
}
.tree-nodes-loading {
  margin-left: 60px;
  & > div {
    width: 220px;
    height: 45px;
    border-radius: $cresta-border-radius;
    overflow: hidden;
    margin-bottom: 10px;
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(5) {
      margin-left: 60px;
    }
    &:nth-child(4) {
      margin-left: 100px;
    }
  }  
}
.tree-node {
  position: relative;
  margin-bottom: 10px;
  z-index: 1;
  &:not(.chat-driver):before {
    width: $group-left-offset;
    height: 50px;
    border-left: 2px solid $tree-lines-color;
    border-bottom: 2px solid $tree-lines-color;
    position: absolute;
    bottom: 23px;
    left: $border-left-offset;
    content: "";
    border-bottom-left-radius: $cresta-border-radius;
  }
  .collapse-button {
    position: absolute;
    top: calc(50% - 16px);
    background: $cresta-background;
    border-radius: 50%;
    left: 20px;
    padding: 3px;
    cursor: pointer;
    &:hover {
      border-color: $cresta-blue;
    }
  }
  & > .node-container {
    height: 45px;
    min-width: 220px;
    padding: 0 15px 0 20px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: $cresta-border-radius;
    overflow: hidden;
    box-shadow: 0 2px 2px rgba(137, 143, 174, 0.35);
    font-weight: 400;
    font-size: 15px;
    position: relative;
    cursor: pointer;
    &.focused {
      border: 2px solid $cresta-blue;
    }
    .tags {
      font-size: 12px;
      margin-right: 8px;
    }
    .tree-node-icon {
      margin-left: -10px;
      margin-right: 20px;
    }
    span:first-of-type {
      margin-right: 20px;
    }
  }

  .count {
    margin-left: auto;
    font-size: 13px;
  }

  &.chat-driver {
    padding-left: $group-left-offset;
    & > .node-container {
      background: $cresta-danube;
      color: white;
    }
  }
  &.stage {
    padding-left: $group-left-offset;
    & > .node-container {
      background: $cresta-soltitude;
      color: $cresta-comet;
    }
  }
  &.intent {
    padding-left: $group-left-offset;
    & > .node-container {
      background: white;
      color: $cresta-comet;
    }
  }

  &.chat-driver > .node-container:hover {
    background: darken($cresta-danube, 5%);
  }
  &.stage > .node-container:hover {
    background: darken($cresta-soltitude, 3%);
    color: $cresta-darkpurple;
  }
  &.intent > .node-container:hover {
    color: $cresta-darkpurple;
  }

  &.deprecated {
    .node-container {
      box-shadow: 0 2px 2px rgba(175, 175, 175, 0.35);
    }
    &.intent {
      .node-container {
        color: $cresta-pearl;
      }
    }
  }
}

.treeFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  :global {
    .search-input {
      width: 50%;
    }
  }

  .filterButtonsContainer {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    border-radius: $cresta-border-radius-lg;
    text-align: left;
    label {
      padding-top: 0 !important;
    }
    .filterButtons {
      border-radius: $cresta-border-radius-lg;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      & > div {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;
        div {
          position: relative;
          z-index: 2;
        }
        &:hover {
          background: rgba(255, 255, 255, 0.5);
        }
        &.active {
          background: white;
        }
      }
      & > div + div {
        margin-left: 20px;
      }
    }
  }
}
