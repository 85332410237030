@import "../../assets/styles/variables.scss";

$bubble-color-agent: white;
$bubble-color-visitor: $cresta-text;
$bubble-color-system: #2c2c2c;

.labeling-message {
  display: block;
  margin: 10px 0;
  padding: 10px 35px;
  position: relative;
  .bubble-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
  }
  .bubble {
    padding: 12px;
    font-size: 13px;
    line-height: 1.3;
    text-align: left;
    max-width: 378px;
    min-width: 95px;
    border-radius: $cresta-border-radius-lg;
    position: relative;
    z-index: 1;
    word-break: break-word;
    &::selection {
      background: $cresta-medium-blue; /* WebKit/Blink Browsers */
    }
    &::-moz-selection {
      background: $cresta-medium-blue; /* Gecko Browsers */
    }
    a {
      color: $cresta-soltitude;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.system .bubble {
    background: #dcdcdc;
    color: $bubble-color-system;
    border-bottom-left-radius: 0;
  }

  &.visitor .bubble {
    background: lighten($cresta-alto, 5%);
    color: $bubble-color-visitor;
    border-bottom-left-radius: 0;
  }

  &.agent .bubble {
    background: $cresta-blue;
    border-bottom-right-radius: 0;
    color: $bubble-color-agent;
    margin-left: auto;
  }

  &.focused .bubble {
    border: #ff9494 solid;
  }

  &.selected .bubble {
    border: #FFEA79 solid;
  }

  &.selected .highlight {
    position: absolute;
    background: rgba(#FFEC9980, 0.5);
    left: -200px;
    right: -200px;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 0;
  }

  &.selectable {
    cursor: pointer;
  }

  &:not(.selected).selectable:hover .highlight {
    position: absolute;
    background: rgba(#FFEC9980, 0.2);
    left: -200px;
    right: -200px;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 0;
  }  

  &.blur {
    .bubble {
      color: transparent;
    }
    &.agent .bubble {
      text-shadow: 0 0 6px rgba($bubble-color-agent, 0.5);
    }
    &.visitor .bubble {
      text-shadow: 0 0 6px rgba($bubble-color-visitor, 0.5);
    }
    &.system .bubble {
      text-shadow: 0 0 6px rgba($bubble-color-system, 0.5);
    }        
  }

  &.error .bubble {
    border: 3px solid #fd4040;
  }

  .message-error {
    color: red;
    font-size: 15px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .message-avatar {
    position: absolute;
    left: -30px;
    bottom: 0px;
    &.right {
      left: auto;
      right: -30px;
    }
  }

  .message-meta {
    display: block;
    width: 100%;
    float: left;
    text-align: left;
    font-size: 12px;
    color: $cresta-text;
    margin-top: 5px;
    position: relative;
    z-index: 1;
    word-break: break-word;
    &.capitalize {
      text-transform: capitalize;
    }
    &.right {
      text-align: right;
    }
  }
  .message-meta + .message-meta {
    margin-top: 0;
  }
}

.labeling-message:after {
  content: "";
  display: table;
  clear: both;
}
