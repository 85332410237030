.ant-modal {
  .ant-modal-content {
    border-radius: $cresta-border-radius-lg;
    background: white;
  }
  .ant-modal-header {
    background: none;
    border: none;
    padding: $ant-modal-padding $ant-modal-padding 0;
  }
  .ant-modal-title {
    font-size: 21px;
    color: $cresta-darkpurple;
  }
  .ant-modal-footer {
    border: none;
    padding: 0 $ant-modal-padding $ant-modal-padding;
    display: flex;
    justify-content: flex-end;
    border-bottom-left-radius: $cresta-border-radius-lg;
    border-bottom-right-radius: $cresta-border-radius-lg;
    button + button {
      margin-left: 30px;
    }
  }
  .ant-modal-body {
    padding: $ant-modal-padding;
  }
  .ant-modal-close {
    display: none;
  }
}