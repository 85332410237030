@import "../../assets/styles/variables.scss";

.concept-tag-wrapper {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .role-icon {
    color: white;

    &.agent {
      background-color: $cresta-blue;
    }

    &.visitor {
      background-color: $cresta-medium-yellow;
    }

    &.driver {
      background-color: $cresta-medium-blue;
    }
  }

  .concept-tag-segment {
    color: $cresta-blue;
    background-color: $cresta-bg-blue;
    border-left: 1px solid white;
    &.green {
      color: #51cf66;
      background: rgba(#51cf66, 0.2);
    }
    &.red {
      color: #ff6b6b;
      background: rgba(#ff6b6b, 0.2);
    }
  }

  .role-icon,
  .concept-tag-segment {
    height: min-content;
    padding: 0 5px;
  }

  & > div:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  & > div:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

@mixin concept-tag($background: $cresta-bg-blue, $text-color: $cresta-blue) {
  .concept-tag-wrapper {
    .concept-tag-segment {
      color: $text-color;
      background-color: $background;
    }
  }
}
