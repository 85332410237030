@import "../../assets/styles/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  .backBtn {
    color: black;
  }

  h1 {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .filters {
    display: flex;
    align-items: center;
    column-gap: 16px;
    justify-content: flex-end;

    .selectContainer {
      width: 200px;
      display: flex;
      .select {
        flex: 1;
      }
    }
    .searchContainer {
      width: 200px;
    }
  }

  .content {
    flex: 1;
    display: flex;
    padding-bottom: 15px;
    margin-top: 20px;    
    flex-direction: row;
    justify-content: space-between;

    .summaryTable {
      width: 100%;
      .labelButton {
        border: 0px;
        border-radius: $cresta-border-radius;

        &.positive {
          background: rgba(81, 207, 102, 0.2);
          color: #51cf66;
        }

        &.negative {
          background: rgba(255, 107, 107, 0.2);
          color: #ff6b6b;
        }

        &.skipped {
          color: $cresta-blue;
          background: rgba($cresta-blue, 0.1);
        }

        &.flagged {
          color: #9775fa;
          background: rgba(#9775fa, 0.2);
        }
      }
    }

    .policyTable {
      width: 100%;
      .reasonDebugOutput {
        font-size: 12px;
        b {
          font-weight: 500;
        }
        ol {
          list-style-type: none;
          counter-reset: item;
          margin: 0;
          padding: 0;
        }
        
        ol > li {
          display: table;
          counter-increment: item;
          margin-bottom: 0.6em;
        }
        
        ol > li:before {
          content: counters(item, ".") ". ";
          display: table-cell;
          padding-right: 0.6em;    
        }
        
        li ol > li {
          margin: 0;
        }
        
        li ol > li:before {
          content: counters(item, ".") " ";
        }
        p {
          margin-bottom: 10px;
        }
      }
      .policyRule {
        padding: 4px;
        &.ruleFalse {
          color: $cresta-red;
          background: white;
        }
      }
    }    
  }
}

.header {
  margin-top: 8px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  h2 {
    color: $cresta-blue;
    font-size: 18px;
  }
  .taskSelectContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-bottom: 16px;
    .select {
      width: 300px;
    }
  }
}

.conversation {
  background: white;
  border-radius: $cresta-border-radius-lg;
  flex-direction: column;
  margin-bottom: 0px;
  margin-left: 20px;
  min-width: 400px;

  &.inactive {
    display: none;
  }

  &.active {
    display: flex;
  }

  .header {
    align-items: center;
    border-bottom: 1px solid $cresta-grey;
    display: flex;
    flex-direction: row;
    height: 55px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .conversationMessages {
    overflow-y: auto;
  }
}

.labelsCount {
  color: $cresta-blue;
}

.taskActions {
  display: flex;
  align-self: center;
}

.missingData {
  color: $cresta-pearl;
}
