@import '../../../assets/styles/variables.scss';
.dashboard {
  width: 100%;
  
  .dashboardTitle {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    .title {
      font-weight: 600;
      font-size: 24px;
      color: $cresta-text;
    }
  }
  .dashboardSndTilte {
    font-size: 14px;
    color: $cresta-text;
    font-weight: 500;
    padding: 0 40px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      padding-right: 4px;
    }
  }
  .dashboardTable {
    background-color: $cresta-background;
    padding: 0 40px 32px;
  }
  .tableDiv {
    background-color: $cresta-background;
    padding: 32px 40px;
    .compare {
      margin-left: 16px;
      margin-right: 8px;
    }
    .statusMessage {
      font-style: italic;
    }
  }
  .tableIntent {
    color: $cresta-blue;
  
    .editRegex {
      &:hover {
        cursor: pointer;
      }

      &.addRegex {
        color: $cresta-blue;
      }
    }
  }
}
/* intents dashboard */
.intentsInputRoot {
  width: 160px;
}
.intentsInput {
  height: 40px;
  font-weight: 500;
  border: 1px solid transparent;
    &::-webkit-input-placeholder { 
    color: #212121; 
    } 
    &:-moz-placeholder { 
    color: #212121; 
    } 
    &::-moz-placeholder { 
    color: #212121; 
    } 
    &:-ms-input-placeholder { 
    color: #212121;
    }
  &:hover {
    border-color: $cresta-blue;
  }
  &:focus {
    background-color: white;
  }
}

/* validation dashboard */

.previewEvalButton {
  border: none;
  background-color: transparent;
  color: $cresta-blue;
  cursor: pointer;
  padding: 0px;
  &:hover {
    text-decoration: underline;
  }
}

/* training dashboard */
.trainingDashboardTitle {
  padding: 0 40px;
  font-weight: 600;
  font-size: 24px;
  color: $cresta-text;
}
.modelName {
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.statusPoint {
  width: 8px;
  height: 8px;
  border-radius: $cresta-border-radius;
  display: inline-block;
  margin-right: 4px;
  &__i {
    background-color: $cresta-medium-yellow;
  }
  &__t {
    background-color: $cresta-lightgreen;
  }
}

.segmentedControlRoot {
  background-color: white;
  margin-bottom: 8px;
  border-radius: $cresta-border-radius;
  padding-bottom: 0;

  .segmentedControlActive {
    background-color: $cresta-pale-blue;
  }

  .segmentedControlLabel {
    color: #21212180;
    font-weight: 400;
    .data-active {
      color: $cresta-blue;
    }
  }
}
/* evaluation results dashboard */
.evaluationResultsTable {
  overflow: hidden;
  border-radius: $cresta-border-radius-lg;
  .tableIntent {
    background-color: $cresta-pale-blue;
    color: $cresta-blue;
    display: inline-block;
    padding: 2px 4px;
    // margin-left: 24px;
    border-radius: $cresta-border-radius-sm;
  }
  .tableFullPrediction {
    background-color: $cresta-white;
    color: $cresta-text;
    display: inline-block;
    padding: 2px 4px;
    border-radius: $cresta-border-radius-sm;
  }
  .tablePrediction {
    background: rgba(255, 107, 107, 0.2);
    color: #ff6b6b;
    display: inline-block;
    padding: 2px 4px;
    border-radius: $cresta-border-radius-sm;
  }
}
