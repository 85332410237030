@import "../../assets/styles/variables.scss";

.header {
  display: flex;
  justify-content: space-between;
}

.filters {
  margin: 40px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > div:first-child {
    margin-right: auto;
  }
  .select {
    min-width: 160px;
  }
  .select + .select {
    margin-left: 20px;
  }  
}

.showArchived {
  margin-top: 10px;
  .showArchivedLabel {
    margin-left: 7px;
    color: $cresta-pearl;
  }
}

.labelsCount {
  color: $cresta-blue;
}

.taskActions {
  display: flex;
  align-self: center;
}

.missingData {
  color: $cresta-pearl;
}

.archive {
  :global {
    .anticon {
      cursor: pointer;
      font-size: 20px;
      margin-left: 20px;
    }
  }
}

.newTaskContainer {
  .taskType {
    width: 200px;
  }
  .fullWidth {
    width: 100%;
  }
  .chatCount, .numMessages {
    width: 100px;
  }
  :global {
    .studio-tag {
      position: relative;
      top: -5px;
      margin-bottom: 5px;
    }
  }
  .showAdvancedToggle {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: $cresta-blue;
  }
  .imageTagInputs {
    input {
      margin-bottom: 10px;
    }
  }
  .modelUrlLabel {
    margin-top: 10px;
  }
}
