@import '../../../assets/styles/variables.scss';


.simulatorHistoryWrapper {
  background: $cresta-background;
  width: calc(100vw - 64px);
  padding: $ant-modal-padding;
  .simulatorHistory {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .simulatorHistoryHeader {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-bottom: 32px;
          .simulatorHistoryTitle {
            button {
              font-size: 24px;
              color: $cresta-text;
              font-weight: 600;
              opacity: .3;
              margin-right: 32px;
              &.active {
                opacity: 1;
              }
            }
          }
      }
      .simulatorModelInputsContainer {
        display: flex;
        flex-direction: row;
        .modelInputs {
          display: flex;
          flex-direction: column;
        }
      }
  }
}