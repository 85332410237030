@import "../../../assets/styles/variables.scss";
@import "../../ConceptTag/ConceptTag.scss";

.intent-concepts-header {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  color: $cresta-text;
  border-bottom: $cresta-row-divider;
  padding: 0 5px 5px;

  .spacer {
    margin: auto;
  }
}

.intent-concepts-search {
  border-bottom: $cresta-row-divider;
}

.intent-concepts-content {
  .placeholder {
    margin-top: 10px;
    color: $cresta-placeholder;
  }

  .intent-concepts-item {
    display: flex;
    flex-direction: row;
    height: 46px;
    align-items: center;
    border-bottom: $cresta-row-divider;
    &:hover {
      background-color: $cresta-active-background;
      cursor: pointer;
    }

    &.focused {
      background-color: $cresta-active-background;
    }

    &.driver {
      @include concept-tag($cresta-medium-blue, white);
    }

    &.stage {
      padding-left: 20px;
      &:hover {
        cursor: default;
      }
    }

    &.intent {
      padding-left: 60px;
    }

    .expansion-button {
      cursor: pointer;
      margin-left: 5px;
      margin-right: 5px;
    }

    .spacer {
      margin: auto;
    }
  }

  .add-new-button {
    color: $cresta-blue;
    margin-top: 8px;
    cursor: pointer;
  }
}
