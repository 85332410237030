@import "../../assets/styles/variables.scss";

.annotation-tag {
  color: $cresta-blue;
  background: rgba($cresta-blue, 0.1);
  border-radius: calc($cresta-border-radius / 2);
  position: relative;
  display: inline-block;
  margin-bottom: 4px;
  padding: 0px 4px;
  font-size: 12px;
  &.positive {
    color: #51cf66;
    background: rgba(#51cf66, 0.2);
  }
  &.negative {
    color: #ff6b6b;
    background: rgba(#ff6b6b, 0.2);
  }
  &.no-intent, &.unspecified {
    color: $cresta-comet;
    background: rgba($cresta-pearl, 0.2);
  }
  &.flagged, &.purple {
    color: #9775fa;
    background: rgba(#9775fa, 0.2);
  }
  &.skipped {
    color: $cresta-blue;
    background: rgba($cresta-blue, 0.1);
  }  
  &.orange {
    color: #FD7E14;
    background: rgba(#FD7E14, 0.2);
  }    
}
.annotation-tag + .annotation-tag {
  margin-left: 5px;
}
