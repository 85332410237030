@import "../../../../assets/styles/variables.scss";

.messageContainer {
  display: block;
  padding: 8px 70px;
  position: relative;
}

.messageContainer:after {
  content: "";
  display: table;
  clear: both;
}

.message {
  padding: 12px;
  font-size: 13px;
  line-height: 1.29;
  text-align: left;
  max-width: 378px;
  min-width: 95px;
  border-radius: $cresta-border-radius-lg;
  float: left;
  text-overflow: ellipsis;
  overflow-x: clip;
}

.system {
  background: #dcdcdc;
  color: #2c2c2c;
  margin-right: 24px;
}

.visitor {
  background: lighten($cresta-alto, 5%);
  color: $cresta-text;
  margin-right: 24px;
  border-top-left-radius: 0;
}

.agent {
  background-color: $cresta-blue;
  border-top-right-radius: 0;
  color: white;
  float: right;
  margin-left: 24px;
  a {
    color: white;
    text-decoration: underline;
    text-decoration-color: white;
  }
}
.agent::selection {
  background: #fff200;
  color: black;
}

.basedOnSuggestion {
  background: #fff;
  border: 1px solid #3588ff;
  float: right;
  margin-left: 24px;
}

.focused {
  background-color: $cresta-lightblue;
}

.selected {
  border: #fff200 solid;
}

.blur {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.blur:hover {
  color: inherit;
  text-shadow: inherit;
}

.leftAdornment {
  position: absolute;
  right: 38px;
  bottom: 32px;
}

.rightAdornment {
  position: absolute;
  left: 38px;
  bottom: 32px;
}

.messageMeta {
  display: block;
  width: 100%;
  float: left;
  text-align: left;
  font-size: 12px;
  color: $cresta-text;
  margin-top: 2px;
  text-transform: capitalize;
}

.agentMessageMeta {
  text-align: right;
}

.aiBadge {
  color: #3588ff;
  margin-right: 5px;
}
