@import "../../../assets/styles/variables.scss";

.taxonomyTabToolbar {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .filter {
    background-color: white;
    color: $cresta-bg-blue;

    :global {
      .mantine-SegmentedControl-label {
        color: $cresta-placeholder;
      }

      .mantine-SegmentedControl-control {
        height: 30px;
      }

      .mantine-SegmentedControl-labelActive {
        background-color: $cresta-bg-blue;
        color: $cresta-blue;
      }
    }
  }

  .select {
    margin-left: 20px;
    width: 400px;
  }
}

.conceptContent {
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  height: 1000px;

  .list {
    width: 38%;
    margin-right: auto;
    height: 100%;
    overflow: auto;
    & > * {
      height: 100%;
      overflow: auto;
    }    
  }

  .editor {
    width: 60%;
    height: 100%;
    overflow: auto;
    & > * {
      height: 100%;
      overflow: auto;
    }
  }
}
