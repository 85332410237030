.ant-input {
  border-radius: $cresta-border-radius;
  border-color: $cresta-alto;
  color: $cresta-darkpurple;
  font-size: 14px;
  line-height: 2;
  &:hover:not(.ant-input-disabled):not(.ant-select-selector-disabled) {
    border-color: $cresta-alto;
  }
}
.ant-input-group-addon {
  background: white;
  color: $cresta-comet;
  border-radius: $cresta-border-radius;
  border-color: $cresta-alto;
}
.ant-input-affix-wrapper {
  border-radius: $cresta-border-radius;
  border-color: $cresta-alto;
  color: $cresta-darkpurple;
  font-size: 14px;
  line-height: 2;
  input {
    border-radius: 0;
  }
  button {
    height: 28px !important;
  }
}
