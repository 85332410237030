@import "../../../assets/styles/variables.scss";
.utterance-list-item {
  display: flex;
  padding: 12px 32px;
  background: white;
  align-items: center;

  &.selected {
    background: $cresta-lightblue !important;
  }  

  cursor: pointer;

  .text {
    flex: 1;
    margin-left: 24px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow-x: clip;
    .highlight {
      background: yellow;
    }
  }
}
