@import "../../assets/styles/variables.scss";

.inputsContainer {
  & > div + div {
    margin-top: 15px;
  }
  margin-top: 20px;
  margin-bottom: 20px;
}
.warningMessage {
  padding: 10px;
  border-radius: $cresta-border-radius-sm;
  background: lighten($cresta-medium-yellow, 45%);
  svg {
    fill: $cresta-medium-yellow;
    margin-right: 10px;
  }
}
.intentSelect {
  min-width: 250px;
}
.importIcon {
  margin-right: 15px;
}
.intentNotFound {
  color: red;
}