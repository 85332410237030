@import "../../assets/styles/variables.scss";

.pills-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  button + button {
    margin-left: 20px;
  }
}
.pill-button {
  display: flex;
  border: none;
  align-items: center;
  background: none;
  padding: 0;
}
.pill-active {
  color: $cresta-blue;
}
.pill-inactive {
  color: $cresta-pearl;
}
.pill-inactive:hover {
  color: $cresta-blue;
  cursor: pointer;
}
.pill-text {
  font-weight: 400;
  flex: 1;
  text-align: left;
}
.pill-number {
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}