@import "../../assets/styles/variables.scss";

.intent-type-tag {
  display: inline-block;
  border-radius: 20px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 500;
  background: $cresta-grey;
  color: $cresta-text;
  &.agent {
    color: $cresta-blue;
    background: rgba($cresta-blue, 0.1);
  }
}
.intent-type-tag + .intent-type-tag {
  margin-left: 5px;
}
