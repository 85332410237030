@import "../../assets/styles/variables.scss";

.regex-tag {
  color: $cresta-text;
  display: inline-block;
  border-radius: $cresta-border-radius-sm;
  padding: 1px 4px;
  font-size: 14px;
  background: $cresta-grey;
}
.regex-tag + .regex-tag {
  margin-left: 5px;
}
.regex-tag-tooltip-item {
  margin: 4px 0;
  span {
    background: $cresta-grey;
    color: $cresta-text;
    border-radius: $cresta-border-radius-sm;
    padding: 2px 4px;
  }
}
.regex-tag-tooltip-item + .regex-tag-tooltip-item {
  margin-top: 10px;
}
