@import "../../../../assets/styles/variables.scss";
.tag-config-container {
    .max-width {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        max-width: 1000px;
        .title-section {
            display: flex;
            align-items: center;
            .title {
                flex: 1;
                font-size: 24px;
            }
        }

        overflow: hidden;
        .content {
            flex: 1;
            display: flex;
            gap: 16px;
            overflow: hidden;

            .panel {
                min-height: 0px;
                max-height: 100%;
                border-radius: $cresta-border-radius-lg;
                background: #fff;
                display: flex;
                flex-direction: column;
                flex: 1;
                overflow: hidden;
            }
            .form-container {
                flex: 2;
                padding: 48px;
                .form-section {
                    display: flex;
                    flex-direction: column;
                    label {
                        font-size: 16px;
                        font-weight: 500;
                    }
                    .description {
                        color: $cresta-placeholder;
                        margin-bottom: 16px;
                    }
                }
            }
            .added-tag-container {
                flex: 1;
                .list-header {
                    font-size: 12px;
                    margin: 12px 16px;
                }
                overflow-x: hidden;
                .list-subheader-container {
                    background-color: $cresta-background;
                    padding: 8px 16px;
                    display: flex;
                    align-items: center;
                    .list-subheader {
                        flex: 1;
                        font-weight: 500;
                        color: $cresta-placeholder;
                        font-size: 11px;
                        // added to stop layout shift when clear all button is shown
                        margin: 3px 0;
                    }
                }
                .list-container {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    padding: 8px 16px;
                }
            }
        }
    }
}
