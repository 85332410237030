@import "../../assets/styles/variables.scss";

.wrapper {
  width: 100%;
  padding-top: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.showArchived {
  margin-top: 10px;
  .showArchivedLabel {
    margin-left: 7px;
    color: $cresta-pearl;
  }
}

.filters {
  margin: 40px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > div:first-child {
    margin-right: auto;
  }
  .select {
    min-width: 160px;
  }
  .select + .select {
    margin-left: 20px;
  }  
}

.labelsCount {
  color: $cresta-blue;
}

.taskActions {
  display: flex;
  align-self: center;
}

.missingData {
  color: $cresta-pearl;
}

.archive {
  .archiveIcon, .unarchiveIcon {
    cursor: pointer;
    font-size: 20px;
    margin-left: 20px;
    &:hover {
      color: $cresta-blue;
    }
  }
  .unarchiveIcon {
    color: $cresta-pearl;
  }  
}

.newTaskContainer {
  .taskType {
    :global {
      .ant-select-selection-item {
        line-height: 20px !important;
      }
    }
  }
  .fullWidth {
    width: 100%;
  }
  .numberInputs {
    display: flex;
    column-gap: 20px;
  }
  .chatCount,
  .numMessages {
    width: 50%;
  }
  :global {
    .studio-tag {
      position: relative;
      top: -5px;
      margin-bottom: 5px;
    }
  }
}
.taskConfigDrawer {
  color: #757575;
  .taskConfigDrawerWrapper {
    display: flex;
    flex-direction: column;
    .taskConfigDrawerTitle {
      font-weight: 500;
      font-size: 16px;
      color: $cresta-text;
      margin-bottom: 24px;
    }
    .taskConfigDrawerType {
      margin-bottom: 24px;
    }
    .taskConfigDrawerIntent {
      margin-bottom: 24px;
    }
    .taskConfigDrawerExamples {
      margin-bottom: 24px;
      .taskConfigDrawerExamplesTitle {
        margin-bottom: 8px;
      }
      .taskConfigDrawerExamplesNumber {
        color: $cresta-text;
      }
    }
  }
  .textWrapper {
    background: $cresta-active-background;
    border-radius: 4px;
    display: inline-block;
    margin-right: 8px;
    color: $cresta-text;
    margin-bottom: 8px;
    padding: 4px;
    font-size: 12px;
  }
}
