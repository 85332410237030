.ruleGraphContent {
  display: flex;
  flex-direction: column;

  .warning {
    margin-top: 10px;
    color: red;
  }

  .container {
    /* 72px: height of tabs, 32px: select, 2*24: container margin */
    height: calc(100vh - 72px - 48px - 32px);
    margin: 24px auto;
    width: 95%;
  }
}

.loading {
  opacity: 0.6;
}