@import "../../assets/styles/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;

  .backBtn {
    color: black;
  }

  .filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.header {
      align-items: center;
      margin-top: 10px;
      .select {
        width: 300px;
      }
    }

    &.quickFilters {
      align-items: flex-end;
      margin: 20px 0 20px;

      .input {
        width: 240px;
      }
    }

    .filter {
      margin-left: 20px;
    }
  }
  .metadataContainer {
    margin-top: 12px;
    margin-bottom: 16px;

    display: flex;
    align-items: center;
    .metadataItem {
      display: flex;
      align-items: center;
      column-gap: 7px;
      .label {
        color: #757575;
        font-size: 13px;
      }
    }
    .divider {
      border-left: 1px solid black;
      margin: 0 10px;
      align-self: stretch;
    }
  }

  .linkLike {
    color: $cresta-blue;
    cursor: pointer;
  }
  .content {
    flex: 1;
    display: flex;
    padding-bottom: 15px;
    flex-direction: row;
    justify-content: space-between;
    .columnTitle > span {
      &:not(:last-child) {
        margin-right: 5px;
      }
  
      &:last-child {
        color: $cresta-blue;
      }
      &.secondaryTitle {
        color: #757575;
      }
    }    
    .summaryTable {
      width: 100%;
      margin-top: 20px;
      .labelButton {
        border: 0px;
        border-radius: $cresta-border-radius;
        width: 90px;

        &.positive {
          background: rgba(81, 207, 102, 0.2);
          color: #51cf66;
        }

        &.negative {
          background: rgba(255, 107, 107, 0.2);
          color: #ff6b6b;
        }

        &.skipped {
          color: $cresta-blue;
          background: rgba($cresta-blue, 0.1);
        }

        &.flagged {
          color: #9775fa;
          background: rgba(#9775fa, 0.2);
        }
      }
    }
  }
}

.selectOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spacer {
  margin: auto;
}
