@import '../../assets/styles/variables.scss';

.tag-input {
  .input-container {
    position: relative;
    line-height: 0;
  }

  .tags-container {
    max-height: 70px;
    overflow-y: auto;
    border-bottom: 1px solid $cresta-alto;
    padding: 10px 0 0;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      border-radius: $cresta-border-radius-sm;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: $cresta-alto;
      border-radius: $cresta-border-radius-sm;
    }
  }

  .enter-icon-container {
    position: absolute;
    bottom: 4px;
    right: 4px;
    background-color: #edf2ff;
    height: 16px;
    width: 16px;
    border-radius: $cresta-border-radius-sm;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  textarea {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
  }

  textarea::-webkit-resizer {
    display: none;
  }
  .studio-tag {
    color: $cresta-text;
  }
  .studio-tag .ant-tag-close-icon {
    color: $cresta-text;
  }
}
