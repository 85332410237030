@import "../../assets/styles/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;

  .filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.header {
      align-items: center;
      margin-top: 10px;

      .select {
        min-width: 300px;
      }
    }

    &.quickFilters {
      align-items: flex-end;
      margin: 20px 0 20px;

      .input {
        width: 240px;
      }
    }

    .filter {
      margin-left: 20px;
    }
  }

  .content {
    display: flex;
    padding-bottom: 15px;
    flex-direction: row;
    justify-content: space-between;

    .summaryTable {
      width: 100%;
      margin-top: 20px;
      .labelButton {
        border: 0px;
        border-radius: $cresta-border-radius;
        width: 90px;

        &.positive {
          background: rgba(81, 207, 102, 0.2);
          color: #51CF66;
        }

        &.negative {
          background: rgba(255, 107, 107, 0.2);
          color: #FF6B6B;
        }

        &.skipped {
          color: $cresta-blue;
          background: rgba($cresta-blue, 0.1);
        }    
        
        &.flagged {
          color: #9775fa;
          background: rgba(#9775fa, 0.2);          
        }
      }
    }
  }
}

.selectOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spacer {
  margin: auto;
}
