.controls {
  padding: 0 90px;
  height: 80px;
  display: flex;
  align-items: center;
  :global {
    .ant-select {
      min-width: 300px;
    }
  }
}
