@import "../../assets/styles/variables.scss";

.multi-tag-container {
  display: inline-flex;

  .multi-tag {
    line-height: 1.3em;
    &.intent {
      font-size: 12px;
      color: $cresta-blue;
      border-radius: calc($cresta-border-radius / 2);
      padding: 1px 4px;
      background: rgba($cresta-blue, 0.1);
    }

    &.conceptTag {
      display: inline-block;
      height: 1.5em;
      line-height: 1.3em;
      text-align: center;
      color: white;
      border-radius: 0.8em;
      background: $cresta-blue;
      padding: 0.1em 0.4em;
    }

    &.driver {
      padding: 0 8px 0;
      background: $cresta-danube;
      border-radius: $cresta-border-radius;
      color: white;
    }

    &.entity {
      font-size: 12px;
      color: $cresta-blue;
      border-radius: calc($cresta-border-radius / 2);
      padding: 1px 4px;
      background: white;
    }
    &.red {
      background: #FFE3E3;
      color:#FF6B6B;
    }
    &.green {
      background: #D3F9D8;
      color: #51CF66;
    }    
    &.lightgreen {
      background: #dfeee3;
      color: #63A375;
    }    
    &.gray {
      background: #ABABAB;
      color: white;
    }      
  }

  .multi-tag+.multi-tag {
    margin-left: 5px;
  }

  &.large {
    .multi-tag {
        font-size: 15px;
    }    
  }

  &.small {
    .multi-tag {
        font-size: 12px;
    }    
  }
}