@import '../../../assets/styles/variables.scss';

.conversation-card {
  padding: 16px;
  cursor: pointer;
  margin-bottom: 2px;
  display: flex;
  padding: 16px;
  width: 100%;
  height: 100px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  margin-bottom: 16px;
  &.active,
  &:hover {
    background: $cresta-pale-blue;
    border-color: $cresta-blue;
  }
  .conversation-content {
    flex: 1;
    flex-direction: column;
    .conversation-title {
      font-size: 14px;
      text-overflow: ellipsis;
      font-weight: 500;
      color: $cresta-black;
      display: block;
    }
    .conversation-time {
      margin-top: 6px;
      font-size: 12px;
      color: #777;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      justify-content: space-between;
      .conversation-message {
        color: $cresta-text;
      }
    }
  }
}
