@import "../../../assets/styles/variables.scss";

.mantine-Modal-body {
  display: flex;
  flex-direction: column;

  .subtitle {
    margin-bottom: 10px;
  }

  .input-form-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  
    & > *:not(:last-child) {
      margin-right: 6px;
    }
  
    .intent-type-toggle:hover {
      cursor: pointer;
    }
  }

  .action-button-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
  }
}