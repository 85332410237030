@import "../../assets/styles/variables.scss";

.intent-drawer {
  .ant-drawer-title, h1, p, span {
    color: $cresta-darkpurple;
    white-space: pre-wrap;
  }
  h1 {
    margin-bottom: 10px;
  }
  .intent-full-name {
    background-color: $cresta-lightblue;
    font-size: 14px;
    color: $cresta-purple;
    font-weight: 500;
    padding: 4px 12px;
    border-radius: $cresta-border-radius;
    display: inline-block;
  }
  label {
    font-size: 13px;
    color: $cresta-balihali;
    text-transform: uppercase;
    display: inline-block;
    padding: 20px 0 10px;
  }
  .sentence-pills {
    display: flex;
    padding: 0 20px 20px;
    margin: 0 -24px;
    button + button {
      margin-left: 20px;
    }
  }
  .sentence-examples {
    width: 100%;
    min-height: 400px;
    position: relative;
    .sentence {
      padding: 10px 0;
    }
    .sentence + .sentence {
      border-top: 1px solid $cresta-linkwater;
    }
  }
}