@import "../../assets/styles/variables.scss";

.loginWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70vh;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  .titleText {
    margin-left: 5px;
    font-size: 25px;
    font-weight: 500;
    white-space: nowrap;
    color: $cresta-darkpurple;
  }
}

.loginControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > * {
    width: 100%;
  }
  & > * + *{
    margin-top: 20px;
  }
}

.signedInMessage {
  background: #e1ffeb;
  color: $cresta-green;
  border-radius: 8px;
  height: 39px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  position: relative;
  :global {
    .anticon {
      margin-right: 10px;
    }
  }
}

.expiredMessage {
  color: darken($cresta-red, 10%);
  border: 1px solid rgba($cresta-red, 0.2);
  border-radius: 8px;
  position: relative;
  padding: 10px 15px;
  margin-bottom: 20px;
  p {
    margin: 0;
  }
}
