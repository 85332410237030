@import "../../assets/styles/variables.scss";

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.header {
    align-items: center;
    margin-top: 10px;

    .dropdownIcon {
      color: $cresta-blue;
      border-radius: $cresta-border-radius;
    }

    .title {
      color: $cresta-blue;
      font-size: large;
      margin-left: 20px;
    }

    .select {
      min-width: 300px;
    }
  }

  &.quickFilters {
    align-items: flex-end;
    margin: 20px 0 20px;

    .input {
      width: 240px;
    }
  }

  .filter {
    margin-left: 20px;
  }
}
.columnTitle > span {
  &:not(:last-child) {
    margin-right: 5px;
  }

  &:last-child {
    color: $cresta-blue;
  }
}
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  .labelsTable {
    width: 100%;
  }
  .clickableRow {
    cursor: pointer;
  }
  .labelRow {
    height: 80px;
    &.activeRow td {
      background-color: $cresta-soltitude !important;
    }    
  }
}

.labelButton {
  border: 0px;
  border-radius: $cresta-border-radius;
  width: 90px;

  &.positive {
    background: rgba(81, 207, 102, 0.2);
    color: #51cf66;
  }

  &.negative {
    background: rgba(255, 107, 107, 0.2);
    color: #ff6b6b;
  }

  &.unsure {
    background: rgba(#9775fa, 0.2);
    color: #9775fa;
  }  
}

.modelReadyLink {
  color: $cresta-blue;
  cursor: pointer;
  margin-left: 20px;
}

.confirmSplitRadios {
  margin: 8px 0 30px;
}

.valueSelect {
  :global {
    .ant-select-selection-item {
      line-height: 20px !important;
    }
  }
}

.selectOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spacer {
  margin: auto;
}
