@import "../../assets/styles/variables.scss";

.labeling-items-display {
  .show-context-button {
    z-index: 10;
  }

  .policy-qa-message-current-intent {
    border-radius: calc($cresta-border-radius / 2);
    position: relative;
    display: inline-block;
    margin-bottom: 4px;
    padding: 0px 4px;
    font-size: 12px;
    color: $cresta-blue;
    background: rgba($cresta-blue, 0.1);
  }

  .policy-qa-message-intents {
    .concept-tag-wrapper {
      margin: 3px;
    }
  }

  .policy-qa-none-incorrect {
    text-decoration: line-through;
    color: $cresta-red;
  }  

  .no-context-display {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    padding: 20px 40px;
    word-wrap: break-word;
    height: 100%;
    .message-error {
      color: red;
      font-size: 15px;
      margin-right: 5px;
      vertical-align: middle;
    }        
  }  

  .chat-display {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: stretch;
    overflow-x: hidden;
    width: 100%;
    & > div:first-child {
      padding: 50px 15px;
      width: 100%;
      max-width: 500px;
    }
  }
}
