@import '../../assets/styles/variables.scss';

.dataComparedTableCell {
  display: flex;
  flex-direction: row;

  .spacer {
    margin: auto;
  }

  .biggerData {
    color: $cresta-green;
  }

  .smallerData {
    color: $cresta-red;
  }
}