@import "../../assets/styles/variables.scss";

.copyable-value {
  button {
    background: none;
    border: none;
    cursor: pointer;
    color: $cresta-blue;
    &:hover {
      color: darken($cresta-blue, 10%);
    }
    margin: 0;
    padding: 0;
    text-align: left;
  }
  
  .icon {
    margin-left: 4px;
    font-size: 16px;
  }
}
