@import "../../assets/styles/variables.scss";

.labeling-stepper {
  display: flex;
  background: white;
  padding: 20px;
  border-radius: $cresta-border-radius-lg;
  .labeling-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .counts {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 5px;
      span {
        font-weight: 300;
        margin-right: 20px;
      }
      span.right-aligned {
        margin-left: auto;
        margin-right: 0;
      }
      b {
        font-weight: 500;
      }
      .blue {
        color: $cresta-blue;
      }
    }
    .progress-bar {
      width: 315px;
      background: $cresta-grey;
      height: 8px;
      border-radius: 2px;
      position: relative;
      overflow: hidden;
      .bar-fill {
        height: 8px;
        background: $cresta-blue;
        position: absolute;
        left: 0;
      }
    }    
  }
  .navigator-buttons {
    display: flex;
    margin-left: 50px;
    align-items: center;
    .navigator-current-chat {
      margin: 0 20px;
      font-weight: 500;
      color: $cresta-text;
    }
  }
}
