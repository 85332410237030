.item-picker {
  .ant-dropdown-trigger {
    display: inline-flex;
  }
}

.item-picker-dropdown {
  .ant-dropdown-menu {
    max-height: 300px;
    overflow: auto;    
  }
}

.item-picker-input {
  border: none;
  outline: 0;
  background: none;
}