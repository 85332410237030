@import "../../assets/styles/variables.scss";

.input-with-label-value {
  display: flex;
  align-items: center;
  .anticon {
    color: $cresta-alto;
    svg {
      font-size: 20px;
      padding: 2px;
    }
    &:hover:not(.disabled) {
      color: $cresta-balihali;
    }
    &:not(.disabled) {
      cursor: pointer;
      color: $cresta-pearl;
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
  .input-with-label-value-toggle {
    margin-right: 10px;
  }
  .input-with-label-value-number {
    margin-right: 10px;
    display: inline-flex;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    justify-content: center;
    border-radius: $cresta-border-radius-sm;
    color: $cresta-blue;
    background: rgba($cresta-blue, 0.1);    
  }
  .ant-input-affix-wrapper {
    margin-right: 10px;
    padding-right: 4px;
  }
  button {
    width: 80px;
    padding: 0 !important;
  }
  &.regex-hidden {
    .input-with-label-value-number {
      opacity: 0.2; 
    }
   .ant-input-affix-wrapper {
     opacity: 0.5;
   } 
  }
}
