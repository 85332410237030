@import "../../assets/styles/variables.scss";

.header {
  display: flex;
  justify-content: space-between;
}

.filters {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 60px 0 20px;

  .spacer {
    margin: auto;
  }

  .filter {
    margin-left: 20px;

    &.select {
      min-width: 160px;
    }

    &.conceptSelect {
      min-width: 200px;
      margin-left: 0;
      margin-right: 20px;
    }
  }
}
.columnTitle > span {
  &:not(:last-child) {
    margin-right: 5px;
  }

  &:last-child {
    color: $cresta-blue;
  }
  &.secondaryTitle {
    color: #757575;
  }
}
.annotationCountCell {
  display: flex;
  column-gap: 5px;
  .secondaryContent {
    color: #757575;
  }
}

.modelReadyModal {
  :global {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #51cf66;
      border-radius: 4px;

      &:after {
        border-color: white;
      }
    }
  }
}

.submitButton {
  background: $cresta-blue !important;
  border-radius: 4px;
  color: white !important;
}

.cancelButton {
  color: $cresta-blue;
}

.importFormItem{
  margin-bottom: 0px !important;
}

.importFormType {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

.importLabelContainer{
  display:flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  row-gap:2rem;
  gap: 2rem;
}

.importLabelCaption{
  font-size: small;
  color:$cresta-blue;
}