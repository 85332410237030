@import "../../assets/styles/variables.scss";

.labeling-details-overview {
  perspective: 600px;
  .labeling-details-overview-container {
    background: white;
    border-radius: $cresta-border-radius-lg;
    width: 330px;
    height: 660px;
    margin-left: 16px;
    transform-origin: center center;
    overflow-x: hidden;
    overflow-y: auto;
    & > div {
      width: 330px;
      padding: 25px;
    }  
  }
  &.inactive {
    .labeling-details-overview-container {
      animation: shrinkOverview 0.3s ease-in-out;
      width: 0;
      margin: 0;
      opacity: 0;
      z-index: -1;
    }
  }
  &.active {
    .labeling-details-overview-container {
      animation: expandOverview 0.3s ease-in-out;
    }
  }
  .overview-header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    p {
      margin-right: 10px;
    }
    div:last-child {
      margin-left: auto;
    }
  }
  .overview-intent-select {
    width: 100%;
    .ant-select {
      width: 100%;
    }
  }  
  .overview-section {
    p:not(.section-title) {
      font-size: 13px;
    }     
    .section-title {
      font-size: 15px;
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }  
  .overview-regex {
    background: $cresta-grey;
    padding: 4px 6px;
    display: inline-block;
    border-radius: $cresta-border-radius-sm;
  }
  .calibration-context {
    label {
      font-size: 12px;
      padding: 0;
      margin-right: 5px;
    }
    p.calibration-comment {
      font-size: 12px;
      color: $cresta-pearl;
    }
  }
  .calibration-context + .calibration-context {
    margin-top: 10px;
  }
  .overview-section + .overview-section {
    border-top: 1px solid #e7e7e7;
    margin-top: 20px;
  } 
  .sentence-pills {
    display: flex;
    margin-top: 10px;
    button + button {
      margin-left: 20px;
    }
  }
  .sentence-examples {
    margin-top: 10px;
    .sentence {
      background: $cresta-lightblue;
      padding: 3px 8px;
      border-radius: calc($cresta-border-radius / 2);
      margin-bottom: 10px;
    }
  }
}

@keyframes shrinkOverview {
  0% {
    width: 330px;
    opacity: 1;
    transform: rotateY(0);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    width: 0;
    transform: rotateY(-90deg);
  }
}

@keyframes expandOverview {
  0% {
    width: 0;
    opacity: 0;
    transform: rotateY(-20deg);
  }
  75% {
    opacity: 0;
    width: 330px;
    transform: rotateY(-20deg) translateX(40px);
  }
  100% {
    opacity: 1;
    width: 330px;
    transform: rotateY(0);
  }
}

@media screen and (max-height: 860px) {
  .labeling-details-overview {
    .labeling-details-overview-container {
      height: 500px;
    }
  }
}