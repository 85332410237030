@import "../../assets/styles/variables.scss";

.concept-detail-wrapper {
  display: flex;
  flex-direction: column;

  .concept-field-name {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 10px;
  }

  .concept-field {
    border-bottom: $cresta-row-divider;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .pills-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    button + button {
      margin-left: 20px;
    }
  }

  .concept-example-wrapper,
  .concept-regex-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 240px;
    overflow-y: auto;
    margin-bottom: 20px;

    .concept-example,
    .concept-regex {
      background: $cresta-active-background;
      padding: 5px;
      border-radius: calc($cresta-border-radius / 2);

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}