@import '../../../assets/styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  width: 320px;
  background: #fff;
  transition: ease-in-out 300ms;
  z-index: 1;
  box-shadow: -4px -10px 14px 3px #00000010;
  border: 1px solid $cresta-alto;
  &[aria-expanded="true"] {
    width: 640px;
  }
  .editorHeader {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid $cresta-alto;
    .icon {
      color: #212121;
      font-weight: 600;
    }
    .historySelect {
      flex-grow: 1;
      .historyOption {
        display: inline-flex;
        align-items: center;
        // &[data-status="draft"] > .optionBadge {
        //   margin-right: 8px;
        //   width: 8px;
        //   height: 8px;
        //   border-radius: 50%;
        //   background-color: $cresta-medium-yellow;
        // }
      }
    }
    .spacer {
      margin: auto;
    }
    .editorStatus {
      font-size: 14px;
      color: $cresta-blue;
      padding: 8px 16px;
    }
  }
  .editor {
    padding: 8px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .buttonGroup{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      button:first-of-type {
        padding-left: 0;
      }
      .divider {
        width: 1px;
        height: 14px;
        background-color: $cresta-alto;
      }
    }
    .dialogPolicyEditor {
      padding: 8px;
      border-radius: 8px;
      border: 1px solid $cresta-alto;
      flex-grow: 1;
    }
  }
}