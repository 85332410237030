// Variables
$cresta-black: #131111;
$cresta-white: #e0e0e0;
$cresta-red: #ff2323;
$cresta-green: #005f20;

// (Name ideas from: https://chir.ag/projects/name-that-color)
$cresta-lightblue: #F0F5FD;
$cresta-lightgreen: #20C997;
$cresta-linkwater: #E1E9F6;
$cresta-blue: #304ffe;
$cresta-medium-blue: #859DD0;
$cresta-bg-blue: #DBE4FF;
$cresta-purple: #28194B;
$cresta-darkpurple: #150A2E;
$cresta-comet: #575C76;
$cresta-balihali: #898FAE;
$cresta-pearl: #A6A8B5;
$cresta-alto: #E0E0E0;
$cresta-danube: #648ff5;
$cresta-soltitude: #DEE9FF;
$cresta-grey: #F6F7F9;
$cresta-background: #F1F3F4;
$cresta-pale-blue: #edf2ff;
$cresta-medium-yellow: #fab005;

$cresta-border-radius-sm: 4px;
$cresta-border-radius: 8px;
$cresta-border-radius-lg: 12px;

$cresta-row-divider: 1px solid #d9d9d9;
$cresta-active-background: #EDF2FF;

// Font color
$cresta-placeholder: #757575;
$cresta-text: #212121;

// Ant design 
$ant-modal-padding: 40px;
