
.ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-radius: $cresta-border-radius;
    border-color: $cresta-alto;
    color: $cresta-darkpurple;
    font-size: 14px;
    &:hover:not(.ant-input-disabled):not(.ant-select-selector-disabled) {
      border-color: $cresta-alto;
    }    
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  &.ant-select-single {
    &.with-icons {
      .ant-select-selector {
        padding-left: 7px;
        padding-right: 7px;
      }
      .ant-select-selection-item {
        padding-left: 7px;
        .tree-node-icon {
          margin-left: -7px;
        }
        * + span {
          margin-left: 10px;
        }
      }
      .ant-select-selection-placeholder {
        margin-left: 4px;
      }
    }
  }
  &.ant-select-multiple {
    .ant-select-selection-item {
      .tree-node-icon {
        transform: scale(0.8);
        margin: 0 3px;
      }
    }
  }
}

.ant-select-dropdown {
  padding: 0;
  border-radius: $cresta-border-radius;
  .ant-select-item-option {
    padding-top: 8px;
    padding-bottom: 8px;
    color: $cresta-comet;
    &.highlighted-option {
      font-weight: 500;
      background-color: $cresta-lightblue;
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 500;
    background-color: white;
    color: $cresta-darkpurple;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #f5f5f5;
    color: $cresta-darkpurple;
    &.highlighted-option {
      background-color: $cresta-lightblue;
    }
  }
  &.with-icons {
    .ant-select-item-option {
      padding-left: 7px;
      padding-right: 7px;
      display: flex;
      align-items: center;
      .ant-select-item-option-content {
        span {
          margin-left: 10px;
        }
      }
    }
  }
}
