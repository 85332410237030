.conversation-filters {
  transition: transform 0.25s ease-in;
  z-index: 5;
  display: flex;
  align-items: center;
  max-width: 1000px;
  .agent-search {
    min-width: 200px;
  }

  .filter-container {
    padding: 5px;
    display: flex;
    input {
      min-width: 200px;
    }
    .engagement-id-input {
      min-width: 200px;
    }
  }
}
