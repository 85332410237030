.column {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

.evaluationResultsTable {
  .averageScoresRow {
    font-weight: 600;
  }

  .inconsistentSupport {
    background: rgba(255, 107, 107, 0.2);
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}
