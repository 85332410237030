@import "../../../assets/styles/variables.scss";

.suggestions-decision {
  width: 300px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column; 
  overflow: hidden;
  justify-content: space-between;
  height: 100%;
  .section-title {
    font-size: 16px;
    font-weight: 500;
  }   
  .note {
    flex: 1;
  }
}