@import "../../assets/styles/variables.scss";
.sidebar-backdrop {
  background: #00000040;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}
.sidebar {
  position: fixed;
  left: 0;
  z-index: 200;
  height: 100vh;
  width: 64px;
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: 0.25s width;
  display: flex;
  flex-direction: column;
  -webkit-transition: 0.25s all;
  transition: 0.25s all;
  ul:hover {
    .menu-out-icon {
      transform: translateX(10%);
      -webkit-transition: 0.25s all;
      transition: 0.25s all;
    }
    .parrot-icon {
      transform: translateX(150%);
      -webkit-transition: 0.25s all;
      transition: 0.25s all;
    }
  }
  .title-svg {
    margin-right: 15px;
    transition: 0.25s all;
  }
  .list-item-svg {
    margin: 0 15px 0 6px;
    -webkit-transition: 0.25s all;
    transition: 0.25s all;
  }
  &.open {
    width: 240px;
    .nav-header {
      .menu-out-icon {
        display: none;
      }
      span {
        display: block;
      }
      .menu-in-icon {
        display: block;
      }
      .parrot-icon {
        transform: translateX(0);
      }
    }
    .nav-header:hover {
      .menu-in-icon:hover {
        path {
          fill: $cresta-blue;
          transition: 0.25s fill;
        }

        rect {
          stroke: $cresta-blue;
          transition: 0.25s stroke;
        }

        span {
          color: $cresta-blue;
        }
      }

      .parrot-icon {
        transform: translateX(0);
      }
      .menu-in-icon {
        cursor: pointer;
      }
    }
    .nav-link-item.parent-menu.active::before {
      display: none;
    }
    .title-svg {
      margin-right: 0;
      -webkit-transition: 0.25s all;
      transition: 0.25s all;
    }
    .list-item-svg {
      margin: 0;
      -webkit-transition: 0.25s all;
      transition: 0.25s all;
    }
    .dropdown {
      cursor: default;
      .letter-avatar {
        display: none;
        opacity: 0;
        transition: 0.25s opacity;
      }
      .customer-picker {
        display: block;
        opacity: 1;
        transition: 0.25s opacity;
      }
    }
    .dropdown:hover {
      background: unset;
    }
  }

  nav {
    flex: 1;
  }

  .nav-header {
    display: flex;
    align-items: center;
    column-gap: 8px;
    border-bottom: 1px solid $cresta-alto;
    padding: 16px;
    padding-left: 8px;
    justify-content: center;
    height: 81px;

    span {
      font-size: 24px;
      font-weight: 500;
      white-space: nowrap;
      transition: 0.25s all;
      color: $cresta-text;
      user-select: none;
      cursor: default;
    }
    .menu-out-icon {
      position: absolute;
      transform: translateX(-180%);
      cursor: pointer;
      transition: 0.25s all;
    }
    .parrot-icon {
      transform: translateX(0);
      transition: 0.25s all;
    }
  }
  .nav-header:hover {
    .menu-out-icon {
      path {
        fill: $cresta-blue;
        transition: 0.25s fill;
      }

      rect {
        stroke: $cresta-blue;
        transition: 0.25s stroke;
      }

      span {
        color: $cresta-blue;
      }
    }
  }

  .dropdown {
    position: relative;
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .letter-avatar {
      display: flex;
      width: 35px;
      height: 35px;
      font-size: 18px;
      opacity: 1;
      transition: 0.25s opacity;
    }
    .customer-picker {
      display: none;
      opacity: 0;
      width: 100%;
    }
  }
  .dropdown:hover {
    background: $cresta-pale-blue;
  }

  .nav-link-item {
    position: relative;
    padding: 15px 16px;
    display: flex;
    column-gap: 18px;
    align-items: center;
    height: 52px;
    background: #fff;
    -webkit-transition: 0.25s all;
    transition: 0.25s all;

    &:before {
      transform: scale(0);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      background: $cresta-blue;
      border-top-right-radius: $cresta-border-radius-sm;
      border-bottom-right-radius: $cresta-border-radius-sm;
      transition: 0.25s transform;
    }
    &.active {
      &:before {
        transform: scale(1);
      }
    }
    &:hover {
      cursor: pointer;
    }
    &.active,
    &:hover {
      background: $cresta-pale-blue;
      -webkit-transition: 0.25s all;
      transition: 0.25s all;
      path {
        fill: $cresta-blue;
        transition: 0.25s fill;
      }

      rect {
        stroke: $cresta-blue;
        transition: 0.25s stroke;
      }

      span {
        color: $cresta-blue;
      }
    }
    svg {
      min-height: 16px;
      min-width: 16px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      transition: 0.25s all;
      color: $cresta-text;
      flex: 1;
    }

    .sub-item {
      padding-left: 36px;
    }
    svg.chevron {
      transform: rotate(0deg);
    }
    svg.chevron.expanded {
      transform: rotate(180deg);
    }
  }

  .bottom-menu-item-container {
    cursor: pointer;
    position: relative;
    padding: 15px 16px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    border-radius: $cresta-border-radius-sm;
    margin-bottom: 12px;

    svg {
      min-width: 20px;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;

      -webkit-transition: 0.25s all;
      transition: 0.25s all;
      left: 80px;
      top: 0;
      color: $cresta-text;
    }

    &:before {
      transform: scale(0);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      background: $cresta-blue;
      border-top-right-radius: $cresta-border-radius-sm;
      border-bottom-right-radius: $cresta-border-radius-sm;
      transition: 0.25s transform;
    }

    &.active,
    &:hover {
      .path-fill {
        fill: $cresta-blue;
      }
      .path-stroke {
        stroke: $cresta-blue;
      }
      .path-stroke,
      .path-fill {
        transition: 0.25s fill;
      }

      span {
        color: $cresta-blue;
      }

      &:before {
        transform: scale(1);
      }
    }
  }
}
.condensed-submenu {
  .condensed-submenu-title {
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 0 0 0 16px;
    margin: 15px 0;
    svg {
      height: 17px;
      width: 17px;
    }
  }
  .ant-popover-inner {
    overflow: hidden;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .nav-link-item {
    position: relative;
    padding: 15px 16px;
    display: flex;
    column-gap: 18px;
    align-items: center;
    height: 52px;
    transition: 0.25s all;
    -webkit-transition: 0.25s all;

    &:before {
      transform: scale(0);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      background: $cresta-blue;
      border-top-right-radius: $cresta-border-radius-sm;
      border-bottom-right-radius: $cresta-border-radius-sm;
      transition: 0.25s transform;
    }
    &.active {
      &:before {
        transform: scale(1);
      }
    }
    &:hover {
      cursor: pointer;
    }
    &.active,
    &:hover {
      background: $cresta-pale-blue;
      path {
        fill: $cresta-blue;
        transition: 0.25s fill;
      }

      rect {
        stroke: $cresta-blue;
        transition: 0.25s stroke;
      }

      span {
        color: $cresta-blue;
      }
    }
    svg {
      min-height: 16px;
      min-width: 16px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      transition: 0.25s all;
      color: $cresta-text;
    }
  }
}
