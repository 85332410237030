@import "../../assets/styles/variables.scss";

.labeling-view {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  border-radius: $cresta-border-radius-lg;
  position: relative;
  text-align: center;
  .labeling-items-display {
    flex: 1 1;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    width: 100%;
    .labeling-items-scroll-wrapper {
      flex: 1 1;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0 auto;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      width: 100%;
      height: 100%;      
    }
  }
  &.horizontal-layout {
    .labeling-decision-panel {
      border-top: 1px solid #E0E0E0;      
    }
  }
  &.vertical-layout {
    flex-direction: row;
    .labeling-decision-panel {
      background: white;
      text-align: left;
      z-index: 1;
      display: flex;      
    }
  }
  .show-context-button {
    border: none;
    color: $cresta-blue;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 15px;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 15px;
  }  
}
