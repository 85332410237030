.dot {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin: 0 5px;
  opacity: 0.8;
  animation: dot-pulse 1s infinite ease-in-out;
}

.dot-1 {
  animation-delay: 0.3s;
}

.dot-2 {
  animation-delay: 0.6s;
}

.dot-3 {
  animation-delay: 0.9s;
}

@keyframes dot-pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  40% {
    transform: translateY(-5px);
    opacity: 1;
  }
}