@import '../../assets/styles/variables.scss';

.modelWizard {
  background: $cresta-background;
  width: 100%;
  padding: $ant-modal-padding;
  .modelWizardHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 32px;
    .modelWizardTitle {
      font-weight: 600;
      font-size: 24px;
      color: $cresta-text;
    }
    .modelWizardButton {
      background-color: #304ffe;
      border: 0;
      border-radius: $cresta-border-radius;
      padding: 10px 16px;
      color: white;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: #2b47e5;
      }
    }
  }

  .modelWizardFilter {
    width: 100%;
    display: flex;
    padding-bottom: 16px;
    justify-content: space-between;
    .modelWizardProLen {
      font-size: 14px;
      font-weight: 500;
      color: $cresta-text;
    }
    .modelWizardSelectGroup {
      display: flex;
    }
  }

  .projectStatusPicker:hover {
    cursor: pointer;
  }

  .modelWizardTableType {
    color: white;
    border-radius:$cresta-border-radius-sm;
    display: inline-block;
    font-weight: 500;
    padding: 2px 4px;
    &__intent {
      background-color: #FFE8CC;
      color: #FD7E14;
    }
    &__smart_compose {
      background-color: #DA77F220;
      color: #DA77F2;
    }

    &__suggestion {
      background-color: #E9FAC8;
      color: #74B816;
    }
  }
  .statusPoint {
    width: 8px;
    height: 8px;
    border-radius: $cresta-border-radius;
    margin-right: 4px;
    &__a {
      background-color: $cresta-pearl;
      opacity: 0.7;
    }
    &__d {
      background-color: $cresta-green;
    }
    &__i {
      background-color: $cresta-medium-yellow;
    }
    &__r {
      border: 1px solid $cresta-green;
    }
  }
  .creatorThumb {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: rgba(247, 131, 172, 0.2);
    color: #f783ac;
    font-size: 10px;
    font-weight: 500;
    margin-right: 4px;
    text-align: center;
    line-height: 20px;
  }
}
