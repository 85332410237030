@import url("https://fonts.googleapis.com/css?family=Inter:300,300i,400,400i,500,500i,700,700i,900,900i");
@import "variables.scss";
// Antd
@import "./antd/ant-select.scss";
@import "./antd/ant-input.scss";
@import "./antd/ant-table.scss";
@import "./antd/ant-modal.scss";
@import "./antd/ant-popover.scss";
@import "./antd/ant-dropdown.scss";
@import "./antd/ant-tabs.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  background-color: $cresta-background;
  font-family: "Inter", "Trebuchet MS", Roboto, Helvetica, Arial, sans-serif;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  display: block;
  color: $cresta-blue;
}

/* Styles that should override antd styles if used inside these classes */
.studio-page {
  color: $cresta-darkpurple;
  font-family: "Inter", "Trebuchet MS", Roboto, Helvetica, Arial, sans-serif;
  h1,
  p {
    white-space: pre-wrap;
  }

  a {
    color: $cresta-blue;
    &:hover {
      color: darken($cresta-blue, 10%);
    }
  }

  h1 {
    font-size: 24px;
    font-weight: 500;
  }
}

.studio-page-wrapper {
  padding: 40px;
  display: flex;
  width: 100%;
  height: calc(100% - 16px);
  padding-left: 40px;
  padding-right: 40px;
  flex-flow: row nowrap;
  justify-content: space-around;  
}

.studio-tag.ant-tag {
  border-radius: $cresta-border-radius;
  border: none;
  white-space: pre-wrap;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

@keyframes appearFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(
    to right,
    $cresta-linkwater 10%,
    darken($cresta-linkwater, 3%) 18%,
    $cresta-linkwater 33%
  );
  background-size: 800px 100px;
  position: relative;
}

.appear-fade {
  animation: appearFade 0.3s ease;
}

.intent-state-indicator {
  width: 9px;
  height: 9px;
  display: inline-block;
  background: white;
  border: 2px solid transparent;
  border-radius: 50%;
  margin-left: auto;
  &.created {
    border-color: $cresta-soltitude;
  }
  &.completed {
    border-color: #4ec841;
    background: #4ec841;
  }
  &.label_ready {
    border-color: #e0ae40;
  }
  &.deprecated {
    background: #a6a8b5;
  }
}

// kbar styles
.kbar {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  & > div {
    border-radius: $cresta-border-radius-sm;
    overflow: hidden;
    background-color: white;
  }
  input {
    outline: none;
    width: 600px;
    height: 48px;
    font-size: 18px;
    border-style: none;
    padding: 0 12px;
    background: white;
    border-bottom: 1px solid $cresta-linkwater;
  }
  #kbar-listbox {
    margin: 10px 0;
    position: relative;
    .group {
      padding: 25px 20px 5px;
      display: flex;
      align-items: center;
      color: $cresta-balihali;
      div {
        display: flex;
        align-items: center;
      }
      svg path {
        fill: $cresta-balihali;
      }
      div + span {
        margin-left: 10px;
      }
    }
    .option {
      height: 40px;
      font-size: 18px;
      background-color:white;
      color: $cresta-text;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 20px;
      border-left: 2px solid transparent;
      padding: 0 20px;
      div:first-child {
        margin-right: 10px;
      }
      &.active {
        border-color: $cresta-blue;
        color: $cresta-blue;
        background: $cresta-active-background;
      }
    }
  }
}