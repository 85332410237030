.messages-container {
  background: white;
  flex: 1;
  .chat-time {
    width: fit-content;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #757575;
    display: block;
    margin: 10px auto;
  }
}
