@import "../../../assets/styles/variables.scss";

.menu-item-container {
    display: flex;
    align-items: center;
    .index {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 24px;
        height: 24px;
        text-align: center;
        color: white;
        border-radius: $cresta-border-radius-sm;
    }
    .label {
        margin-left: 10px;
    }
    .menu-item-0 {
        background: #da77f2;
    }
    .menu-item-1 {
        background: #ff6b6b;
    }
    .menu-item-2 {
        background: #fd7e14;
    }
    .menu-item-3 {
        background: #82c91e;
    }
    .menu-item-4 {
        background: #da77f2;
    }
    .menu-item-5 {
        background: #ff6b6b;
    }
    .menu-item-6 {
        background: #fd7e14;
    }
    .menu-item-7 {
        background: #82c91e;
    }
    .menu-item-8 {
        background: #da77f2;
    }
    .menu-item-9 {
        background: #ff6b6b;
    }
    .menu-item-10 {
        background: #fd7e14;
    }
    .menu-item-11 {
        background: #82c91e;
    }
    .menu-item-12 {
        background: #da77f2;
    }
    .menu-item-13 {
        background: #ff6b6b;
    }
    .menu-item-14 {
        background: #fd7e14;
    }
    .menu-item-15 {
        background: #82c91e;
    }
}
