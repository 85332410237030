.ant-table {
  background: none;
  td, th {
    color: $cresta-text;
    border-color: #E5E5E5;
    background: white;
  }
  tr.greyed-row {
    td {
      background: $cresta-grey;
    }
  }
  tr.child-row {
    td {
      background: lighten($cresta-pale-blue, 2%);
    }
  }
}
.ant-table-tbody > tr:last-child > td {
  border-bottom: none;
  &:first-child {
    border-bottom-left-radius: $cresta-border-radius-lg;
  }
  &:last-child {
    border-bottom-right-radius: $cresta-border-radius-lg;
  }
}
.ant-table-container {
  border-top-left-radius: $cresta-border-radius-lg;
  border-top-right-radius: $cresta-border-radius-lg;
}
.ant-table-container table > thead > tr {
  &:first-child th:first-child {
    border-top-left-radius: $cresta-border-radius-lg;
  }
  &:first-child th:last-child {
    border-top-right-radius: $cresta-border-radius-lg;
  }
}
.ant-table-thead > tr > th {
  background: white;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: $cresta-active-background;
}