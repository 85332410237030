.model-builder-confusion-matrix {
  .predictions {
    display: flex;
    justify-content: flex-start;

    .tag+.tag {
      margin-left: 5px;
    }
  }

  .filters {
    margin: 60px 0 20px;
    display: flex;
    justify-content: flex-end;
    .select {
      min-width: 160px;
    }
    .select + .select {
      margin-left: 20px;
    }
  }
}