@import "../../assets/styles/variables.scss";

.dialogPolicy {
  padding: 40px;
  width: 100%;
  .dialogPolicy-tabs {
    margin-bottom: 20px;
    a {
      display: unset;
      font-size: 24px;
      color: $cresta-text;
      font-weight: 600;
      opacity: .3;
      margin-right: 32px;
      &.active {
        opacity: 1;
      }
    }
  }
  .dialogPolicy-info {
    display: flex;
    margin-bottom: 26px;
    .dialogPolicy-infoLabel {
      height: 22px;
      font-weight: 400;
      font-size: 12px;
      color: $cresta-placeholder;
      margin-right: 8px;
      display: flex;
      align-items: center;
      border-right: 1px solid $cresta-alto;
      &:last-child {
        border: none;
      }
      .mantine-Input-input {
        min-height: 22px;
        height: 22px;
        margin-left: 4px;
      }
    }
  }
  .dialogPolicy-editWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: calc(100vh - 160px);
    background: #FFFFFF;
    border: 1px solid $cresta-alto;
    border-radius: 16px;
    .dialogPolicy-editCardWrapper {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $cresta-alto;
      .dialogPolicy-editCard {
        padding: 14px 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        opacity: 0.4;
        &.active {
          opacity: 1;
        }
        &.disabled {
          cursor: not-allowed;
        }
        &:nth-child(-n + 2) {
          border-right: 1px solid $cresta-alto;
        }
      }
    }
    .dialogPolicy-edit {
      padding: 16px;
      height: 100%;
      width: 100%;
      .dialogPolicy-editInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        height: 80px;
        div[role='group']{
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          button:first-of-type {
            padding-left: 0;
          }
          .btnOpacity {
            opacity: .3;
            color: $cresta-text;
          }
        }
        .dialogPolicy-disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }
        .dialogPolicy-editTime {
          font-weight: 400;
          font-size: 12px;
          color: $cresta-placeholder;
          display: flex;
          align-items: center;
          width: 100%;
          padding: 8px 0px;
          .dialogPolicy-deployedTime {
            margin-right: 8px;
          }
          .dialogPolicy-changeTime {
            margin-left: 8px;
          }
          .spacer {
            margin: auto;
          }
          .dialogPolicy-resetButton {
            font-size: 12px;
            color: #304FFE;
            &.disabled {
              cursor: not-allowed;
              opacity: 0.3;
            }
          }
        }
      }
      .dialogPolicy-editor {
        display: inherit;
        height: calc(100% - 88px);
        width: 100%;
        border-radius: 8px;
        border: 1px solid #E0E0E0;
        padding: 16px 0px;
      }
    }
  }
}
.divider {
  display: inline-block;
  width: 1px;
  height: 14px;
  background-color: $cresta-alto;
  &.withSpace {
    margin-left: 10px;
    margin-right: 10px;
  }
}
