@import '../../../assets/styles/variables.scss';

.messagePreviewerContainer {
  flex: 1;
  margin: 0px 12px;
  height: 100%;
  background: #FFF;
  border-radius: $cresta-border-radius-lg;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .syntheticConvoHeader {
    display: flex;
    flex-direction: column;
    border-bottom: $cresta-row-divider;
    padding: 0 15px;

    .convoName,
    .convoTime {
      display: inline-flex;
      align-items: center;
      min-height: 48px;
    }

    .convoName .convoNameInput {
      border: none;
      border-bottom: 1px dashed $cresta-placeholder;
      padding: 0;
      margin-left: 8px;
      color: $cresta-blue;
      width: 400px;

      &:focus {
        border-color: $cresta-blue;
      }
    }

    .convoTime input {
      padding: 0;
      text-align: center;
      margin: 0 8px;
      width: 36px;
      font-size: 12px;
      min-height: 0;
      height: 24px;
      &::placeholder {
        color: $cresta-placeholder;
      }
    }
  }

  .messagesList {
    flex-grow: 1;
    margin: 16px 0px;
    height: 0;
    .scrollContainer {
      height: calc(100% - 60px);
      overflow-y: auto;
    }

    .startTime {
      display: flex;
      justify-content: center;
      color: $cresta-placeholder;
      margin: 16px 0px 4px;
    }

    .timePicker {
      border: 1px solid transparent;
      box-shadow: none;
      padding: 0 16px;
      border-radius: $cresta-border-radius-sm;
      
      & input {
        font-size: 12px;
        color: $cresta-placeholder;

        &:focus {
          color: $cresta-blue;
        }
      }
    }
  }

  .messageEditor {
    display: flex;
    align-items: flex-end;
    padding: 4px 8px;
    margin: 16px;
    border: 1px solid $cresta-alto;
    border-radius: $cresta-border-radius;

    .roleButton{ 
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      button[aria-label='switch role'] {
        border: 2px solid transparent;
        border-radius: 50%;

        &:hover {
          border: 2px solid $cresta-pale-blue;
        }
      }
    }

    .divider {
      border-left: $cresta-row-divider;
      height: auto;
      align-self: stretch;
      width: 1px;
      margin: 4px 6px;
    }

    .contentInput {
      flex-grow: 1;

      textarea {
        border: none;
        min-height: 28px;
        padding: 6px;
      }
    }

    button[aria-label="enter"] {
      padding: 0;
      width: 36px;
      height: 36px;
    }
  }
}