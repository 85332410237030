.loading-component {
    display: flex;
    align-items: center;
    justify-content: center;
  
  .page {
    flex: 1;
  }
}

.loading-component-inline {
  margin-left: 8px;
}
