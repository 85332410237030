@import "../../../assets/styles/variables.scss";

.conversation-outcome-concepts-header {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  color: $cresta-text;
  border-bottom: $cresta-row-divider;
  padding: 0 5px 5px;

  .spacer {
    margin: auto;
  }
}

.conversation-outcome-concepts-search {
  border-bottom: $cresta-row-divider;
}

.conversation-outcome-concepts-content {
  .placeholder {
    margin-top: 10px;
    color: $cresta-placeholder;
  }

  .conversation-outcome-concepts-item {
    display: flex;
    flex-direction: row;
    height: 46px;
    align-items: center;
    border-bottom: $cresta-row-divider;
    &:hover {
      background-color: $cresta-active-background;
      cursor: pointer;
    }

    &.focused {
      background-color: $cresta-active-background;
    }

    &.possible-outcome {
      padding-left: 44px;
    }

    .expansion-button {
      margin-left: 5px;
      margin-right: 5px;
    }

    .spacer {
      margin: auto;
    }
  }

  .add-new-button {
    color: $cresta-blue;
    margin-top: 8px;
    cursor: pointer;
  }
}
