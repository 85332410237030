@import "../../assets/styles/variables.scss";
$horizontal-padding: 3px;
.labeling-entity {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  padding: 20px 40px;
  word-wrap: break-word;
  line-height: 30px;
  height: 100%;
  ::selection {
    background-color: #ffe066;
    color: black;
  }
  #label-container{
    max-width: 100%;
  }

  .context-menu-item {
    display: flex;
    align-items: center;
    .index {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;

      text-align: center;
      color: white;
      border-radius: $cresta-border-radius-sm;
    }
    .label {
      margin-left: 10px;
    }
  }

  .entity {
    padding: 12px $horizontal-padding 5px;
    position: relative;
    border-radius: $cresta-border-radius-sm;
    .ant-popover .ant-popover-inner-content {
      padding: 8px;
    }
  }
  .entity::before {
    content: attr(data-label);
    width: calc(100% - $horizontal-padding * 2);
    position: absolute;
    font-size: 10px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    top: -8px;
  }

  .menu-item-0 {
    background: #da77f2;
  }
  .menu-item-1 {
    background: #ff6b6b;
  }
  .menu-item-2 {
    background: #fd7e14;
  }
  .menu-item-3 {
    background: #82c91e;
  }
  .menu-item-4 {
    background: #da77f2;
  }
  .menu-item-5 {
    background: #ff6b6b;
  }
  .menu-item-6 {
    background: #fd7e14;
  }
  .menu-item-7 {
    background: #82c91e;
  }
  .menu-item-8 {
    background: #da77f2;
  }
  .menu-item-9 {
    background: #ff6b6b;
  }
  .menu-item-10 {
    background: #fd7e14;
  }
  .menu-item-11 {
    background: #82c91e;
  }
  .menu-item-12 {
    background: #da77f2;
  }
  .menu-item-13 {
    background: #ff6b6b;
  }
  .menu-item-14 {
    background: #fd7e14;
  }
  .menu-item-15 {
    background: #82c91e;
  }

  .label-0 {
    background-color: #da77f220;
    user-select: none;
  }
  .label-0::before {
    color: #da77f2;
  }
  .label-1 {
    background-color: #ff6b6b20;
    user-select: none;
  }
  .label-1::before {
    color: #ff6b6b;
  }
  .label-2 {
    background-color: #fd7e1420;
    user-select: none;
  }
  .label-2::before {
    color: #fd7e14;
  }
  .label-3 {
    background-color: #82c91e20;
    user-select: none;
  }
  .label-3::before {
    color: #82c91e;
  }
  .label-4 {
    background-color: #da77f220;
    user-select: none;
  }
  .label-4::before {
    color: #da77f2;
  }
  .label-5 {
    background-color: #ff6b6b20;
    user-select: none;
  }
  .label-5::before {
    color: #ff6b6b;
  }
  .label-6 {
    background-color: #fd7e1420;
    user-select: none;
  }
  .label-6::before {
    color: #fd7e14;
  }
  .label-7 {
    background-color: #82c91e20;
    user-select: none;
  }
  .label-7::before {
    color: #82c91e;
  }
  .label-8 {
    background-color: #da77f220;
    user-select: none;
  }
  .label-8::before {
    color: #da77f2;
  }
  .label-9 {
    background-color: #ff6b6b20;
    user-select: none;
  }
  .label-9::before {
    color: #ff6b6b;
  }
  .label-10 {
    background-color: #fd7e1420;
    user-select: none;
  }
  .label-10::before {
    color: #fd7e14;
  }
  .label-11 {
    background-color: #82c91e20;
    user-select: none;
  }
  .label-11::before {
    color: #82c91e;
  }
  .label-12 {
    background-color: #da77f220;
    user-select: none;
  }
  .label-12::before {
    color: #da77f2;
  }
  .label-13 {
    background-color: #ff6b6b20;
    user-select: none;
  }
  .label-13::before {
    color: #ff6b6b;
  }
  .label-14 {
    background-color: #fd7e1420;
    user-select: none;
  }
  .label-14::before {
    color: #fd7e14;
  }
  .label-15 {
    background-color: #82c91e20;
    user-select: none;
  }
  .label-15::before {
    color: #82c91e;
  }
  .label-selection {
    background-color: #ffe066;
    user-select: none;
  }
  .message-error {
    color: red;
    font-size: 15px;
    margin-right: 5px;
    vertical-align: middle;
  }
}
.entity-selection-popover {
  position: absolute;
  padding: 8px;
  overflow: hidden;
  border-radius: $cresta-border-radius-lg;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background: white;
}
