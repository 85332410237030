.labeling-unsure-popover {
  p {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 15px;    
  }
  & > div {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
}