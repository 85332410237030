@import "../../assets/styles/variables.scss";

.intentRegexDiffBanner {
  background: rgba($cresta-medium-yellow, 0.2);
  border-radius: $cresta-border-radius-sm;
  padding: 10px 10px;
  margin-bottom: 20px;

  .intentRegexDiffIcon {
    color: $cresta-medium-yellow;
  }
}

.regexTableIntent {
  display: flex;
  flex-direction: row;
  align-items: center;

  .spacer {
    margin: auto;
  }
}

.regexTableArchive {
  :global {
    .anticon {
      cursor: pointer;
      font-size: 20px;
      margin-left: 20px;
    }
  }  
}
