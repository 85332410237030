@import "../../assets/styles/variables.scss";

.conversations {
  .conversation-holder-heading {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #212121;
    margin: 0 0 15px;
    display: flex;
    flex-direction: row;
    height: 36px;
  }
  .filter-controls {
    // allows filter dropdowns to remain visible
    z-index: 20;
  }
  .chat-count {
    padding: 8px;
    font-size: 14px;
    color: #2c2c2c;
    font-weight: normal;
  }
  .panels {
    border-radius: 4px;
    display: flex;
    height: calc(100vh - 240px);
    flex-direction: row;
    flex-flow: row nowrap;
    justify-content: flex-start;
    overflow: hidden;
    column-gap: 16px;

    .utterance-search-panel {
      // Would allow users to resize panel
      // resize: horizontal;
      overflow: auto;
      border-radius: $cresta-border-radius-lg;
      display: flex;
      flex-direction: column;
      flex: 1;
      background: white;
      > :not(:last-child) {
        border-bottom: 1px #f1f1f1 solid;
      }
    }
    .chats-list-holder {
      border-right: 1px solid #f1f1f1;
      flex-basis: 285px;
      display: flex;
      flex-direction: column;
      position: relative;
      max-width: 332px;
      border-radius: $cresta-border-radius-lg;
      // to keep border radius applied to children
      overflow: hidden;
    }
    .chat-panel-holder {
      min-width: 600px;
      flex: 1;
      overflow: hidden;
      display: flex;
      background: white;
      border-radius: $cresta-border-radius-lg;
    }
    .side-panel-holder {
      flex-basis: content;
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      background-color: lightgrey;
      max-width: 600px;
      .side-panel-header {
        width: 100%;
        height: 30px;
        .btn {
          background-color: white;
          border: solid;
          border-width: 1px;
          margin-right: 3px;
          margin-left: 3px;
        }
      }
      .side-panel-body {
        display: flex;
        flex-direction: row;
        height: 100%;
      }
    }
  }
}
