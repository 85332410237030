@import "../../assets/styles/variables.scss";

.conversation-preview {
  background: white;
  border-radius: $cresta-border-radius-lg;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-left: 20px;
  width: 400px;
  min-height: 600px;
  max-height: 800px;
  flex-shrink: 0;

  &.inactive {
    display: none;
  }

  &.active {
    display: flex;
  }

  .conversation-preview-header {
    width: 100%;
    flex-shrink: 0;
    align-items: center;
    border-bottom: 1px solid $cresta-grey;
    display: flex;
    flex-direction: row;
    height: 55px;
    padding-left: 15px;
    div + p {
      margin-left: 20px;
    }
  }

  .conversation-preview-loading {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .conversation-preview-messages {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    flex: 1;
    .chat-display > div {
      padding: 0 20px;
    }
  }
}