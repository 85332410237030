@import "../../assets/styles/variables.scss";

.intent-regex-diff-icon {
  color: $cresta-medium-yellow;
}

.intent-regex-diff-wrapper {
  width: 550px;
  display: flex;
  flex-direction: column;

  .intent-regex-diff-tip {
    margin: 10px 5px;
  }

  .intent-regex-diff-table {
    .ant-table-container {
      border: none;
    }
  
    .ant-table-tbody>tr>td {
      padding: 5px;
      border-bottom: 1px solid #f0f0f0;
    }
  
    .ant-table-tbody>tr>td:first-of-type,
    .ant-table-thead>tr>th:first-of-type {
      border-left: 1px solid #f0f0f0;
    }
  }
}
