.letter-avatar {
  background: lightblue;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar {
    flex: 1;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .agent {
    color: white;
    background: #304ffe;
  }
  .visitor {
    color: #212121;
    background: #e0e0e0;
  }
  .system {
    color: #fff;
    background: #989898;
  }
}
