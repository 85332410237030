
@import "../../assets/styles/variables.scss";

.error-message-wrapper {
  padding: 50px;
  h1, p {
    color: $cresta-darkpurple;
  }
  .error-message {
    background: white;
    padding: 10px 20px;
    color: darken($cresta-red, 10%);
    border-radius: 6px;
    margin: 10px 0;
  }
}