@import "../../assets/styles/variables.scss";

.concept-tag-option,
.concept-tag-pill {
  display: inline-block;
  background-color: $cresta-blue;
  color: white;
  border-radius: 16px;
  font-weight: 500;
  font-size: 12px;
  padding: 4px 8px;
  margin-left: 8px;
}

.concept-tag-pill {
  display: flex;
  flex-direction: row;

  .tag-close-button {
    margin-left: 4px;
    cursor: pointer;
  }
}

form.ant-form {
  label,
  .ant-form-item-label {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-form-item {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .mantine-RadioGroup-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;

    &>*:not(:last-child) {
      margin-right: 5px;
    }
  }

  .intent-type {
    span:last-child {
      margin-left: 10px;
      font-weight: 500;
    }
  }

  .pills-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    button + button {
      margin-left: 20px;
    }
  }

  .list-items-container {
    display: flex;
    flex-direction: column;
    max-height: 240px;
    overflow-y: auto;
    margin-top: 10px;
  
    .list-item {
      background: $cresta-active-background;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px;
      border-radius: calc($cresta-border-radius / 2);

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .spacer {
        margin: auto;
      }
    }
  }
}