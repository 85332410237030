@import '../../assets/styles/variables.scss';

.wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;

  h1 {
    margin-bottom: 40px;
  }
}

.select {
  width: 100%;
}

.filters {
  display: flex;
  & > div {
    min-width: 200px;
  }
  & > div + div {
    margin-left: 40px;
  }
}

.historyList {
  margin-top: 40px;
  margin-bottom: 20px;
  max-width: 1200px;
  width: 90%;
  .historyRecord {
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgba($cresta-alto, 0.5);
    padding: 20px 0;
    width: 100%;
    flex: 1;
    div:nth-child(2) {
      flex: 1;
    }
  }
  .changeIcon {
    width: 60px;
  }
  .time {
    color: $cresta-pearl;
    font-size: 13px;
  }
  .changeDescription {
    font-size: 16px;
    color: $cresta-comet;
    flex: 1;
    width: 100%;
    & > * + * {
      margin-left: 6px;
    }
    b {
      font-weight: 500;
      color: $cresta-text;
    }
    a {
      font-weight: 500;
      color: $cresta-blue;
      display: inline;
    }
    :global {
      .labeling-task-status {
        display: inline-flex;
      }
    }
  }
  .diffComparison {
    display: flex;
    justify-content: space-between;
    width: 100%;
    & > div {
      padding: 10px 20px;
      margin: 10px 0;
      white-space: pre-wrap;
      flex: 1;
      background: white;
      border-radius: $cresta-border-radius-lg;
    }
  }
  .diffComparisonSentence {
    &:not(:first-child) {
      padding-top: 5px;
      margin-top: 5px;
    }
  }
  .diffComparisonSentence + .diffComparisonSentence {
    border-top: 1px solid $cresta-linkwater;
  }
}

.loadMore {
  padding-bottom: 70px;
}