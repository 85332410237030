@import '../../../assets/styles/variables.scss';

.simulatorResult {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .simulatorResultHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 32px;

    .simulatorResultTitle {
      button {
        font-size: 24px;
        color: $cresta-text;
        font-weight: 600;
        opacity: .3;
        margin-right: 32px;

        &.active {
          opacity: 1;
        }
      }
    }

    .simulatorResultButton {
      border: 0;
      padding: 10px 16px;
      color: $cresta-white;
      font-size: 14px;
      font-weight: 500;

      &:disabled {
        cursor: pointer;
      }
    }
  }

  .simulatorResultMessage {
    width: 100%;
    display: flex;
    align-items: center;
    color: $cresta-text;
    margin-bottom: 32px;

    .simulatorResultCompareTitle {
      display: flex;
      border-right: 1px solid #E0E0E0;
      margin-right: 16px;
      padding-right: 16px;

      .simulatorResultCompareLabel {
        color: $cresta-placeholder;
      }
    }

    .simulatorResultCompareCreator {
      padding-right: 16px;
      margin-right: 16px;

      .simulatorResultCompareLabel {
        color: $cresta-placeholder;
        margin-right: 4px;
      }
    }

    .simulatorResultCreateTime {
      border-right: 1px solid #E0E0E0;
      margin-right: 16px;
      padding-right: 16px;

      .simulatorResultCreateTimeLabel {
        color: $cresta-placeholder;
      }
    }
  }

  .buttonsGroup {
    padding-bottom: 16px;
  }

  .simulatorResultContentWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 16px;
    padding-bottom: 16px;
    background-color: #FFF;

    .simulatorResultSelect {
      display: flex;
      justify-content: space-between;
      padding: 12px 16px;
      border-bottom: 1px solid #E0E0E0;

      .simulatorResultToggle {
        display: flex;
        align-items: center;
        flex-wrap: wrap-reverse;

        .simulatorResultToggleTitle {
          color: $cresta-text;
          font-weight: 500;
          flex-grow: 1;
        }

        .simulatorResultActive {
          background: #EDF2FF;
          border: 1px solid $cresta-blue;
          border-radius: 8px;
        }

        button {
          margin-right: 8px;
        }
      }

      .simulatorResultFilter {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: flex-end;

        .dialogButton {
          height: 40px;
          width: 114px;
          border: 1px solid #E0E0E0;
          text-align: center;
          border-radius: 8px;
          margin-left: 16px;
          font-size: 12px;

          &[data-active="true"] {
            border-color: $cresta-blue;
            color: $cresta-blue;
            background-color: $cresta-bg-blue;
          }
        }

        .simulatorModelSetupButton {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
          margin-left: 16px;

          button {
            font-weight: 500;
            font-size: 13px;
            color: #FFFFFF;
            width: 128px;
            height: 38px;
            border-radius: 8px;
          }
        }

        .simulatorResultFilterSelect,
        .simulatorResultFilterInput {
          margin-left: 16px;
          width: 200px;

          :global {
            .ant-select-selector {
              display: flex;
              align-items: center;

              .ant-select-selection-item {
                height: 22px;
              }
            }
          }
        }
      }
    }

    .simulatorResultContent {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex: 1;

      .tableContainer {
        width: 100%;
        background: #FFF;

        &[aria-expanded="true"] {
          width: calc(100% - 348px);
        }
      }

      .simulatorConvo {
        width: 0;
        min-width: 0;
        height: 75vh;
        overflow: auto;
        background: #FFF;
        border-radius: $cresta-border-radius-lg 0 0 $cresta-border-radius-lg;

        &[aria-expanded="true"] {
          width: 332px;
          min-width: 332px;
        }

        .simulatorConvoTitle {
          height: 48px;
          padding: 8px 16px;
          border-bottom: 1px solid $cresta-alto;
          font-weight: 500;
          color: $cresta-text;
        }

        .simulatorConvoList {
          overflow: auto;
          flex: 1;
          height: calc(75vh - 48px);

          .chat-card {
            border: 10px solid red;
          }
        }
      }

      .editorContainer {
        display: flex;
        background: #FFF;
        border-radius: 0 $cresta-border-radius-lg $cresta-border-radius-lg 0;
        z-index: 4;

        &[aria-expanded="false"] {
          display: none;
        }
      }
    }
  }
}

.simulatorResultIntentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 16px;
  background-color: #FFF;
  padding-bottom: 16px;

  .simulatorResultIntentSelect {
    display: flex;
    justify-content: flex-end;
    padding: 12px 16px;
    border-bottom: 1px solid #E0E0E0;

    .simulatorResultIntentFilter {
      display: flex;
      flex-wrap: wrap-reverse;
      justify-content: flex-end;

      .simulatorResultFilterSelect,
      .simulatorResultFilterInput {
        margin-left: 16px;
        width: 200px;

        :global {
          .ant-select-selector {
            display: flex;
            align-items: center;

            .ant-select-selection-item {
              height: 22px;
            }
          }
        }
      }
    }
  }

  .simulatorResultIntentContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 1;

    .tableContainer {
      width: 100%;
      background: #FFF;
    }
  }
}

.simulator {
  background: $cresta-background;
  width: calc(100vw - 64px);
  padding: $ant-modal-padding;
}