@import "../../assets/styles/variables.scss";

.wrapper {
  background: $cresta-lightblue;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  .wrapperHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h1 {
      margin-right: 20px;
    }
    div:last-child {
      margin-left: auto;
      display: flex;
      button {
        margin-left: 15px;
      }
    }
  }
}

.editRegexWrapper {
  width: 100%;
  padding: 40px;
  position: relative;
  border-radius: $cresta-border-radius-lg;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .greyText {
    color: $cresta-balihali;
  }
  .redText {
    color: $cresta-red;
  }  
}

.whiteCol {
  margin-top: 20px;
  background: white;
  padding: 24px;
  border-radius: $cresta-border-radius-lg;
  height: 85vh;
  overflow: auto;
}

.regex {
  @extend .whiteCol;
  & > div:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .regexInputList {
    margin-top: 30px;
    margin-bottom: 20px;
    & > div {
      margin-top: 20px;
    }
    &.isHovered {
      & > div {
        &:not(.hoveredMatchRegex) {
          opacity: 0.3;
        }
      }
    }
  }
}

.messages {
  @extend .whiteCol; 
  .messagesHeader {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .greyText {
      margin-left: 20px;
    }
  }
  .messagesSubheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .regexWarningMessage {
    color: $cresta-red;
    margin-bottom: 20px;
  }
  .messageList {
    margin-top: 20px;
    .message {
      margin-bottom: 20px;
      & > div:first-child {
        padding: 10px;
        border-radius: $cresta-border-radius-sm;
        background: $cresta-grey;
        margin-bottom: 5px;
      }
      .matchIndicesContainer {
        display: inline-block;
        &:hover .regexIndex:not(:hover){
          opacity: 0.5;
        }        
      }
      .regexIndex {
        cursor: default;
        margin-left: 10px;
        display: inline-flex;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        line-height: 24px;
        justify-content: center;
        border-radius: $cresta-border-radius-sm;
        color: $cresta-blue;
        background: rgba($cresta-blue, 0.1);  
        &.negative {
          color: #FF6B6B;
          background: rgba(#FF6B6B, 0.2);
        }
      }     
      .regexIndex + .greyText {
        margin-left: 10px;
      } 
    }
  }
}

