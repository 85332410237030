@import '../../../assets/styles/variables.scss';

.simulatorResult {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .simulatorResultHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 32px;
        .simulatorResultTitle {
            font-weight: 600;
            font-size: 24px;
            color: $cresta-text;
          }
        .simulatorResultButton {
            border: 0;
            padding: 10px 16px;
            color: $cresta-white;
            font-size: 14px;
            font-weight: 500;

            &:disabled {
              cursor: pointer;
            }
        }
    }
    .simulatorResultSelect {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .simulatorResultToggle {
        display: flex;
        align-items: center;
        .simulatorResultToggleTitle {
          color: $cresta-text;
          font-weight: 500;
        }
        .simulatorResultActive {
          background: #EDF2FF;
          border: 1px solid #304FFE;
          border-radius: 8px;
        }
        button {
          margin-right: 8px;
        }
      }
      .simulatorResultFilter {
        .simulatorModelSetupButton {
          font-weight: 500;
          font-size: 13px;
          color: #FFFFFF;
          width: 128px;
          height: 38px;
          border-radius: 8px;
        }
        .simulatorResultFilterSelect {
          margin-right: 16px;
        }
        .simulatorResultFilterInput {
          width: 200px;
          margin-right: 16px;
        }
      }
    }
    .simulatorResultContent {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex: 1;
      height: 100%;
      .tableContainer{
        width: 100%;
        &[aria-expanded="true"] {
          width: calc(100% - 348px);
        }
      }
      .simulatorConvo {
        width: 0;
        min-width: 0;
        height: 100%;
        background: #FFF;
        border-radius: $cresta-border-radius-lg;

        &[aria-expanded="true"] {
          width: 332px;
          min-width: 332px;
          margin-right: 16px;
        }
        .simulatorConvoTitle {
          height: 48px;
          padding: 8px 16px;
          border-bottom: 1px solid $cresta-alto;
          font-weight: 500;
          color: $cresta-text;
        }
        .simulatorConvoList {
          overflow: auto;
          flex: 1;
          .chat-card {
            border: 10px solid red;
          }
        }
      }
    }
}
.simulatorDashboardCardTitle {
  padding: 2px;
  border-radius: 4px;
  &[data-type="total"] { background-color: $cresta-background; color: $cresta-text;  }
  &[data-type="same"] { background-color: $cresta-bg-blue; color: $cresta-blue; }
  &[data-type="added"] { background-color: #D3F9D8; color: #51Cf56; }
  &[data-type="removed"] { background-color: #FFE3E3; color: $cresta-red;  }
  &[data-type="convo-same"] { width: 56px; background-color: $cresta-bg-blue; color: $cresta-blue; }
  &[data-type="convo-added"] { width: 56px; background-color: #D3F9D8; color: #51Cf56; }
  &[data-type="convo-removed"] { width: 80px; background-color: #FFE3E3; color: $cresta-red;  }
}