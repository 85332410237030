@import '../../../assets/styles/variables.scss';

.simulatorModelSetup {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .simulatorResultTitle {
      button {
        font-size: 24px;
        color: $cresta-text;
        font-weight: 600;
        opacity: .3;
        margin-right: 32px;
        &.active {
          opacity: 1;
        }
      }
    }
    .simulatorModelSetupContent {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex: 1;
      max-height: calc(100vh - 128px);
      overflow-x: scroll;
      min-width: 800px;
      .simulatorConvo {
        width: 332px;
        min-width: 332px;
        height: 100%;
        background: #FFF;
        border-radius: $cresta-border-radius-lg;
        display: flex;
        flex-direction: column;
        .simulatorConvoTitle {
          height: 48px;
          padding: 8px 16px;
          border-bottom: 1px solid $cresta-alto;
          font-weight: 500;
          color: $cresta-text;
        }
        .simulatorConvoImport {
          position:relative;
          height: 48px;
          padding: 8px 16px;
          border-bottom: 1px solid $cresta-alto;
          color: $cresta-text;
          display: flex;
          .simulatorConvoImportIcon {
            display: flex;
            align-items: center;
            padding: 8px;
            width: 32px;
            height: 32px;
            background: #FFF;
            border: 1px solid $cresta-alto;
            border-radius: 4px;
            margin-left: 8px;
            cursor: pointer;
            &.disabled {
              cursor: not-allowed;
            }
          }
        }
        .simulatorAddConvo {
          position: relative;
          display: flex;
          align-items: center;
          padding: 12px 16px;
          height: 40px;
          background: #FFF;
          border-bottom: 1px solid $cresta-alto;
          color: $cresta-blue;
          cursor: pointer;
          font-weight: 500;
          .anticon {
            color: $cresta-blue;
          }
          .simulatorAddConvoText {
            margin-left: 8px;
          }
          &.disabled {
            color: #757575;
            cursor: not-allowed;
          }
        }
        .simulatorConvoList {
          height: 100%;
          overflow: auto;
        }
      }
      .simulatorConversation {
        flex: 1;
        padding: 24px 0px;
        margin-right: 16px;
        margin-left: 16px;
        height: 100%;
        background: #FFF;
        border-radius: $cresta-border-radius-lg;
        overflow-y: scroll;
      }
      .messageContainer {
        min-width: 400px;
        flex-grow: 1;
      }
      .simulatorSetModel {
        position: relative;
        padding: 24px;
        width: 332px;
        min-width: 332px;
        height: 100%;
        background: #FFF;
        border-radius: $cresta-border-radius-lg;
        overflow-y: scroll;
        .simulatorSetModelTitle {
          height: 32px;
          display: flex;
          justify-content: space-between;
          .simulatorSetModelProd {
            padding: 4px 8px;
            width: 77px;
            height: 25px;
            background: $cresta-pale-blue;
            border-radius: 4px;
            color: $cresta-blue;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover {
              background: $cresta-blue;
              color: #FFF;
            }
          }
          .simulatorSetModelTitleText {
            font-weight: 500;
          }
        }
        .simulatorSetModelError {
          width: 100%;
          padding-bottom: 24px;
          margin-bottom: 24px;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: $cresta-red;
          border-bottom: 1px solid $cresta-alto;
        }
        .simulatorSetModelFormWrapper {
          .simulatorSetModelFormTitle {
            font-weight: 500;
            color: $cresta-text;
            margin-bottom: 16px;

          }
          .simulatorSetModelForm {
            border-bottom: 1px solid $cresta-alto;
            margin-bottom: 24px;
            .simulatorSetModelFormItem {
              padding: 0px;
              &.policyURLItem {
                margin-bottom: 6px;
              }
            }

            .toggleDraftPolicy {
              margin-bottom: 12px;
              padding-bottom: 0;
            }
          }
          .simulatorSetModelCompare {
            display: flex;
            justify-content: space-between;
            .simulatorSetModelCompareTitle {
              width: 96px;
              height: 17px;
              font-weight: 500;
              font-size: 14px;
              color: #212121;
            }

          }
          .simulatorShowTagInput {
            padding: 0px;
          }
        }
      }
    }
}
.simulatorResultDrawerTitle {
  .simulatorResultDrawerTitleText {
    margin-left: 16px;
  }
}
.simulatorResultDrawer {
  display: flex;
  .simulatorResultFormWrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    .simulatorResultForm {
      &.left {
        margin-right: 32px;
        border-right: 1px solid #e0e0e0;
        padding-right: 32px;
      }
      flex: 1;
      .simulatorSetModelFormTitle {
        font-weight: 500;
        color: $cresta-text;
        margin-bottom: 16px;
  
      }
      .simulatorSetModelForm {
        border-bottom: 1px solid $cresta-alto;
        margin-bottom: 24px;
        .simulatorSetModelFormItem {
          padding: 0px;
          &.policyURLItem {
            margin-bottom: 6px;
          }
        }

        .toggleDraftPolicy {
          margin-bottom: 12px;
          padding-bottom: 0;
        }
      }
      .simulatorSetModelCompare {
        display: flex;
        justify-content: space-between;
        .simulatorSetModelCompareTitle {
          width: 96px;
          height: 17px;
          font-weight: 500;
          font-size: 14px;
          color: #212121;
        }
        .simulatorSetModelProd {
          padding: 4px 8px;
          width: 77px;
          height: 25px;
          background: $cresta-pale-blue;
          border-radius: 4px;
          color: $cresta-blue;
          display: flex;
          align-items: center;
          cursor: pointer;
          &:hover {
            background: $cresta-blue;
            color: #FFF;
          }
        }
      }
      .simulatorShowTagInput {
        padding: 0px;
      }
    }
  }
}