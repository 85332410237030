@import "../../assets/styles/variables.scss";

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.labelingWrapper {
  position: relative;
  border-radius: $cresta-border-radius-lg;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  & > div {
    display: flex;
  }
  .boxContainer {
    background: white;
    border-radius: $cresta-border-radius-lg;
    width: 860px;
    height: 500px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    .buttons {
      padding: 32px 0;
      display: flex;
      justify-content: center;
    }
    .noMoreLabels {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $cresta-comet;
      font-weight: 500;
      font-size: 22px;
    }
    .labelingFinished {
      display: flex;
      height: 100%;
      width: 100%;
      position: relative;
      // align-items: center;
      justify-content: center;
      overflow: auto;
      animation: fadeIn 1s ease-in-out;
      .gifWrapper {
        display: block;
        border-radius: $cresta-border-radius;
        overflow: hidden;
        margin-top: 40px;
        margin-bottom: 10px;
      }
      h1 {
        font-weight: 500;
        color: $cresta-text;
      }
      p {
        color: $cresta-comet;
        font-size: 16px;
        margin-bottom: 10px;
      }
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.labelingHeader {
  justify-content: flex-start;
  align-items: center;
  max-width: 860px;
  position: relative;
  top: 20px;
  h1 {
    margin: 0 15px 0 0;
  }
}

.guidelinesButton {
  background: none;
  border: none;
  margin-left: auto;
  color: $cresta-text;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
  :global {
    .anticon {
      font-size: 20px;
      margin-right: 10px;
      transform: rotate(90deg);
    }
  }
  &.active {
    color: $cresta-blue;
    :global {
      .anticon {
        transform: rotate(-90deg);        
      }
    }
    &:hover {
      color: darken($cresta-blue, 10%);
    }
  }
}

.labelingFooter {
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  & > div {
    display: flex;
  }
  .footerButtons {
    button + button {
      margin-left: 20px;
    }
  }
}

.highlightedText {
  background: rgba(#FFEA79, 0.5);
}

.conceptTitle {
  background: #fff;
  color: $cresta-blue;
  padding: 0 10px;
  border-radius: 6px;
  font-size: 18px;
}

.assigneeWarningText {
  font-size: 12px;
  margin-bottom: 10px;
  color: $cresta-comet;
}
* + .assigneeWarningText {
  margin-top: 15px;
}

.loadingMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(white, 0.75);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;  
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (min-height: 860px) {
  .wrapper {
    .boxContainer {
      height: 660px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .wrapper {
    .boxContainer {
      width: 1000px;
    }
  }
}