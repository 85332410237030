@import "../../../assets/styles/variables.scss";

.binary-decision {
  width: 100%;
  padding: 30px 0;  
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  .buttons-group {
    align-items: center;
    justify-content: center;      
    display: flex;
    & > button + button {
      margin-left: 20px;
    }
  }
  .prediction-panel {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    div + div {
      margin-left: 20px;
    }
    span {
      margin: 0 5px;
    }
  }   
}