@import '../../../assets/styles/variables.scss';

.simulatorResult {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  .simulatorResultHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 16px;

    .simulatorResultTitle {
      button {
        font-size: 24px;
        color: $cresta-text;
        font-weight: 600;
        opacity: .3;
        margin-right: 32px;
        &.active {
          opacity: 1;
        }
      }
    }

    .simulatorResultButton {
      border: 0;
      padding: 10px 16px;
      color: $cresta-white;
      font-size: 14px;
      font-weight: 500;

      &:disabled {
        cursor: pointer;
      }
    }

    .simulatorInfo {
      width: 100%;
      display: flex;
      align-items: center;
      color: $cresta-text;
      .simulatorResultCompareTitle {
        display: flex;
        border-right: 1px solid #E0E0E0;
        align-items: center;
        padding-right: 16px;
        margin-right: 16px;
        .simulatorResultCompareLabel {
          color: $cresta-placeholder;
        }
      }
      .simulatorResultCompareCreator {
        border-right: 1px solid #E0E0E0;
        padding-right: 16px;
        margin-right: 16px;
        .simulatorResultCompareLabel {
          color: $cresta-placeholder;
          margin-right: 4px  ;
        }
      }
      .simulatorResultCompareTime {
        border-right: 1px solid #E0E0E0;
        padding-right: 16px;
        .simulatorResultCompareLabel {
          color: $cresta-placeholder;
        }
      }
    }

    .buttonsGroup {
      padding-bottom: 16px;
    }

    .simulatorModelSetupButton {
      font-weight: 500;
      font-size: 13px;
      color: #FFFFFF;
      width: max-content;
      height: 38px;
      border-radius: 8px;
    }

    .simulatorDashboard {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      padding-top: 16px;

      .simulatorDashboardCard {
        background-color: white;
        border-radius: 12px;
        flex-grow: 1;
        padding: 16px;
        text-transform: capitalize;
        user-select: none;

        &Title {
          display: inline-block;
          margin-bottom: 18px;
          padding: 4px;
          background-color: $cresta-alto;
          border-radius: 4px;
          line-height: 1;
          font-size: 12px;
          font-weight: 500;

          &[data-type="total"] {
            background-color: $cresta-background;
            color: $cresta-text;
          }

          &[data-type="same"] {
            background-color: $cresta-bg-blue;
            color: $cresta-blue;
          }

          &[data-type="added"] {
            background-color: #D3F9D8;
            color: #51Cf56;
          }

          &[data-type="removed"] {
            background-color: #FFE3E3;
            color: $cresta-red;
          }
        }

        &:not(:last-child) {
          margin-right: 16px;
        }

        &Body {
          display: flex;

          &>div {
            flex: 1;

            &:not(:last-child) {
              border-right: 1px solid $cresta-alto;
              margin-right: 12px;
            }

            [aria-label="value"] {
              font-size: 16px;
              color: $cresta-text;
              font-weight: 500;
            }

            [aria-label="key"] {
              font-size: 12px;
              color: $cresta-placeholder;
            }
          }
        }
      }
    }
  }

  .simulatorResultCompare {
    width: 100%;
    display: flex;
    align-items: center;
    color: $cresta-text;

    .simulatorResultCompareTitle {
      display: flex;
      border-right: 1px solid #E0E0E0;
      padding-right: 16px;
      margin-right: 16px;
      align-items: center;

      .simulatorResultCompareLabel {
        color: $cresta-placeholder;
      }
    }

    .simulatorResultCompareCreator {
      border-right: 1px solid #E0E0E0;
      padding-right: 16px;
      margin-right: 16px;

      .simulatorResultCompareLabel {
        color: $cresta-placeholder;
        margin-right: 4px;
      }
    }

    .simulatorResultCompareTime {
      border-right: 1px solid #E0E0E0;
      padding-right: 16px;

      .simulatorResultCompareLabel {
        color: $cresta-placeholder;
      }
    }
  }

  .buttonsGroup {
    padding-bottom: 16px;
  }

  .simulatorResultSelect {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background-color: white;
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid $cresta-alto;

    .simulatorResultToggle {
      display: flex;
      align-items: center;

      .simulatorResultToggleTitle {
        color: $cresta-text;
        font-weight: 500;
      }

      .simulatorResultActive {
        background: #EDF2FF;
        border: 1px solid #304FFE;
        border-radius: 8px;
      }

      button {
        margin-right: 8px;
      }
    }

    .simulatorResultFilter {
      .simulatorResultFilterSelect {
        width: 200px;
        margin-right: 16px;

        &Option {
          line-height: 2;
        }
      }

      .simulatorResultNumberFilterSelect {
        width: 200px;
      }

      .simulatorResultFilterInput {
        width: 200px;
      }
    }
  }

  .simulatorResultContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    background-color: white;
    border-radius: 0 0 12px 12px;

    .simulatorConvo {
      width: 0;
      min-width: 0;
      background: #FFF;
      display: flex;
      flex-direction: column;

      &[aria-expanded="true"] {
        width: 332px;
        min-width: 332px;
        border-right: 1px solid $cresta-alto;
      }

      .simulatorConvoTitle {
        height: 48px;
        padding: 8px 16px;
        border-bottom: 1px solid $cresta-alto;
        font-weight: 500;
        color: $cresta-text;
      }

      .simulatorConvoList {
        overflow: auto;
        flex: 1;

        .chat-card {
          border: 10px solid red;
        }
      }
    }

    .tableContainer {
      width: 100%;
      flex-grow: 1;
      background-color: white;
      border-radius: 0 0 12px 0;

      &[aria-expanded="true"] {
        width: calc(100% - 348px);
      }

      .simulatorResultIntentTable {
        .linkButton {
          color: #304FFE;
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}