@import "../../assets/styles/variables.scss";

.concept-tags-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px 0;
  align-items: center;
  min-height: 40px;
  padding: 5px;
  font-size: 12px;

  .concept-tag {
    border: $cresta-row-divider;
    margin-right: 5px;
    padding: 0.2em 0.4em;
    border-radius: 1em;

    &:hover {
      cursor: pointer;
    }
  
    &.selected {
      background: $cresta-blue;
      color: white;
      border: none;
    }
  }
}