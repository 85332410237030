@import "../../assets/styles/variables.scss";

.intent-info-button {
  color: $cresta-comet;
  background: white;
  border: none;
  border-radius: $cresta-border-radius;
  display: inline-flex;
  align-items: center;
  padding: 3px 10px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: $cresta-blue;
  }
  svg {
    margin-right: 10px;
  }
}