@import "../../../assets/styles/variables.scss";

.no-chat-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-panel {
  display: flex;
  flex: 1;
  flex-direction: column;
  .chat-panel-head {
    border-bottom: 1px solid #f1f1f1;
    padding: 16px 40px;
    display: flex;
    .name-and-url {
      display: table-cell;
      vertical-align: middle;
      flex: 1;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      justify-content: center;

      p {
        font-size: 14px;
        font-weight: 500;
        color: #212121;
      }
    }
    .header-suffix {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      row-gap: 8px;
      justify-content: space-around;
      p {
        color: #757575;
      }
      .ant-tag {
        margin-right: 0;
      }
      .clickable-tag {
        cursor: pointer;
      }
    }
  }
}
