@import "../../assets/styles/variables.scss";

.role-icon-wrapper {
  display: inline-block;
  width: 1.3em;
  height: 1.3em;
  line-height: 1.3em;
  font-weight: 500;
  text-align: center;
  border-radius: $cresta-border-radius-sm;
  color: white;

  &.agent {
    background-color: $cresta-blue;
  }

  &.visitor {
    background-color: $cresta-medium-yellow;
  }

  &.stage, &.stage-end, &.stage-begin {
    background-color: #BAC8FF;
  }

  &.driver {
    background-color: $cresta-medium-blue;
  }
}