@import "../../assets/styles/variables.scss";

.editable-tag-group-container {
  display: flex;
  flex-direction: row;

  .editable-tag-group-item {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: $cresta-medium-blue;
    color: white;

    .close-button {
      margin-left: 0.5em;
      &:hover {
        cursor: pointer;
      }
    }

    &.add-new {
      background-color: $cresta-blue;
      color: white;

      &:hover {
        cursor: pointer;
      }

      &.disabled {
        background-color: rgba($color: $cresta-blue, $alpha: 0.3);
        cursor: not-allowed;
      }
    }

    &:not(:last-child) {
      margin-right: 1em;
    }
  }
}

.editable-tag-group-popover-content {
  .mantine-Group-root {
    gap: 5px;
  }

  .mantine-CheckboxGroup-input {
    height: 16px;
    width: 16px;
  }
  .mantine-CheckboxGroup-icon {
    height: 10px;
    width: 10px;
    margin: 3px;
  }
  .mantine-CheckboxGroup-label {
    font-size: 14px;
  }
  .mantine-Button-root {
    margin-top: 10px;
  }
}

@mixin editable-tag-group($background: $cresta-medium-blue, $text-color: white, $add-button-background: $cresta-blue, $add-button-text-color: white) {
  .editable-tag-group-container {
    .editable-tag-group-item {
      background-color: $background;
      color: $text-color;

      &.add-new {
        background-color: $add-button-background;
        color: $add-button-text-color;

        &.disabled {
          background-color: rgba($color: $add-button-background, $alpha: 0.3);
        }
      }
    }
  }
}