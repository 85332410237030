@import "../../assets/styles/variables.scss";

.intents-diff-wrapper {
  display: flex;
  flex-direction: column;
  
  .intents-diff-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .intents-diff-deprecated-intent {
      background: rgba(#ff6b6b, 0.2);
    }

    .intents-diff-new-intent {
      background: rgba(#304ffe, 0.2);
    }
  }
}