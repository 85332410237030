.labeling-task-type-tag {
  border-radius: 20px;
  display: inline-block;
  padding: 0 8px;
  font-size: 12px;
  &.auto-labeling {
    color: #22B8CF;
    background: rgba(#22B8CF, 0.2);
  }
  &.dynamic {
    color: #be4bdb;
    background: rgba(#be4bdb, 0.2);
  }
  &.calibration {
    color: #845EF7;
    background: rgba(#845EF7, 0.2);
  }
  &.manual {
    color: #F06595;
    background: rgba(#F06595, 0.2);
  }
  &.dense {
    color: #12B886;
    background: rgba(#12B886, 0.2);
  }
  &.unspecified {
    color: #6d6d6d;
    background: rgba(#6d6d6d, 0.2);
  }
  &.hint {
    color: #FD7E14;
    background: rgba(#FD7E14, 0.2);
  }
  &.intent-prediction {
    color: #F06595;
    background: rgba(#F06595, 0.2);
  }        
  &.intent-recall {
    color: #FD7E14;
    background: rgba(#FD7E14, 0.2);
  }     
  &.qa-policy {
    color: #be4bdb;
    background: rgba(#be4bdb, 0.2);
  }     
  &.qa-suggestions {
    color: #1479fd;
    background: rgba(#1479fd, 0.2);
  }          
  &.entity {
    color: #FD7E14;
    background: rgba(#FD7E14, 0.2);
  }    
  &.summarization {
    color: #82C91E;
    background: rgba(#82C91E, 0.2);
  }
  &.qa-summarization {
    color: #12B886;
    background: rgba(#12B886, 0.2);
  }  
  &.misclassification {
    color: #845EF7;
    background: rgba(#845EF7, 0.2);
  }
  &.misc-review {
    color: #845EF7;
    background: rgba(#845EF7, 0.2);
  }
  // TO BE REMOVED
  &.clo {
    color: #1479fd;
    background: rgba(#1479fd, 0.2);
  }      
  &.clo-sparse {
    color: #F06595;
    background: rgba(#F06595, 0.2);
  }        
  &.clo-dense {
    color: #12B886;
    background: rgba(#12B886, 0.2);
  }
  &.dual {
    color: #82C91E;
    background: rgba(#82C91E, 0.2);
  }      
}
