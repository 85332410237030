@import '../../../assets/styles/variables.scss';

.hint-timing-model-config-editor {
  height: 800px;

  .hint-timing-model-uris {
    width: 400px;
    input {
      width: 200px;
    }
  }

  .hint-timing-model-uri {
    color: $cresta-text;
    width: 200px;
    &.focused {
      color: $cresta-blue
    }
  }
}