@import "../../../assets/styles/variables.scss";

.multiple-binary-decision {
  width: 250px;
  box-sizing: content-box;
  padding: 25px;
  display: flex;
  flex-direction: column;
  .header {
    padding-bottom: 10px;
    .search-input {
      input {
        background: $cresta-grey;
        height:40px;
        padding-left: 15px;
      }
    }
  }
  .concepts {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    .concept {
      cursor: pointer;
      height: 33px;
      background: $cresta-grey;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      width: 100%;
      border-radius: calc($cresta-border-radius / 2);
      color: $cresta-blue;
      &.deprecated {
        span:first-child {
          text-decoration: line-through;
        }
      }
      &:hover {
        background: rgba($cresta-blue, 0.2);
        &.positive {
          background: rgba(#51CF66, 0.3); 
        }
        &.negative {
          background: rgba(#FF6B6B, 0.3); 
        }
      }
      &.positive {
        color: #51CF66;
        background: rgba(#51CF66, 0.2);          
      }
      &.negative {
        color: #FF6B6B;
        background: rgba(#FF6B6B, 0.2);          
      }
      &.hotkey-active {
        background: rgba($cresta-blue, 0.4);
        &.positive {
          background: rgba(#51CF66, 0.4); 
        }
        &.negative {
          background: rgba(#FF6B6B, 0.4);
        }
      }                
    }
    .concept + .concept {
      margin-top: 10px;
    }
  }
  .buttons {
    & > div {
      display: flex;
      margin-top: 20px;
    }
    button {
      width: 100%;
    }
    button + button {
      margin-left: 20px;
    }
  }  
}

@media screen and (min-width: 1400px) {
  .multiple-binary-decision {
    width: 300px;
  }
}