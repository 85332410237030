@import '../../../assets/styles/variables.scss';

.chat-card {
  padding: 16px;
  cursor: pointer;
  margin-bottom: 2px;
  display: flex;
  &.active,
  &:hover {
    background: $cresta-pale-blue;
  }
  .card-content {
    flex: 1;
    flex-direction: column;

    .agent-name {
      font-size: 14px;
      text-overflow: ellipsis;
      font-weight: 500;
      color: $cresta-black;
      display: block;
    }

    .date-time {
      margin-top: 6px;
      font-size: 12px;
      color: #777;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
    }
  }
  .card-right {
    display: flex;
    flex-direction: column;
    .card-delete {
      text-align: right;
    }
    .card-message {
      display: flex;
      align-items: center;
      .card-time {
        margin-right: 10px;
      }
    }
  }
}
