@import "../../assets/styles/variables.scss";

.ModelWizardProject {
  width: 100%;
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;

  .projectHeader {
    padding: 16px 40px 24px;

    button {
      padding: 0px;
      height: 24px;
      margin-bottom: 8px;
      font-size: 12px;
      color: $cresta-placeholder;
    }

    .projectTitle {
      display: flex;
      flex-direction: row;

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: $cresta-black;
      }

      .spacer {
        margin: auto;
      }
    }
  }
}

.modelWizardProjectStepper {
  flex: 1;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .steps {
    justify-content: flex-start;
    padding: 0px 40px 0;
    .step {
      flex: 0 1 200px;
      color: $cresta-placeholder;
      padding-bottom: 8px;
      border-bottom: 1px solid rgba($cresta-text, 0.3);
      .stepIcon {
        background-color: $cresta-text;
        border: none;
        color: $cresta-white;
        opacity: 0.3;
      }
      &:last-child {
        flex-grow: 1;
      }
    }
    .step[data-progress] {
      color: $cresta-blue;
      border-bottom: 2px solid $cresta-blue;
      .stepIcon {
        background-color: $cresta-blue;
        border: none;
        color: $cresta-white;
        opacity: 1;
      }
    }
    .step[data-completed] {
      @extend .step, [data-progress];
      border-bottom: 1px solid $cresta-placeholder;
      opacity: 0.5;
    }

    .separator {
      display: none;
    }
  }
}
